<template>
    <div class="text-center">

        <v-dialog
                v-model="dialog"
                scrollable
                max-width="599px"
            >
                <v-card rounded class="pa-3">
                    <v-card-text v-html="ratalityTermsAndConditions"></v-card-text>
                    <v-card-actions  class="pa-3">

                        <v-btn
                            color="green darken-1"
                            text
                            @click="dialog = false"
                        
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog> 

    </div>
</template>

<script>

export default {

    props:{
      ratalityTermsAndConditionsDialog: Boolean,
      ratalityTermsAndConditions: String
    },
    data: () => ({
        dialog: false,
    }),
    created() {
    this.initialize();
    },
    watch: {
        ratalityTermsAndConditionsDialog: function (newVal, oldVal) {   
            if (!oldVal && newVal){
                this.dialog = true;
                this.$emit('reverseRatalityTermsAndConditionsFlag', false);
            }
        },
    },
    methods: {
        initialize() {
            //init code goes here
        
        }
    },
}
</script>
