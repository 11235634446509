<template>
    <div>
        <v-main >
            <!-- Provides the application the proper gutter -->
            
            <v-app-bar 
                dark 
                fixed 
                app
            >
                <div class="d-flex">
                <v-img contain height="50" width="50"  src="icons/icon-152x152.png"></v-img>
                <v-toolbar-title class="d-flex px-3 mt-3">  Lion Roars Bookings</v-toolbar-title>  
                </div>
            </v-app-bar>

            <v-container style="max-width:800px;"  >
                <v-card flat color="transparent" style="max-height:calc(100vh - 80px);">
                    <v-card-text class="hide-scrollbar pa-0">
                        <v-row>
                            <v-col cols="12" class='pt-0' v-if="!guestNotConfirmed">
                                <v-card class="booking-details elevated rounded-lg" flat v-if="!passwordIsReset">
                                    <v-card-title class="text-h5">
                                    Password Reset
                                    </v-card-title>
                                    <v-card-subtitle>
                                        Type in new password for guest login.
                                    </v-card-subtitle>
                                    <v-card-text>
                                        <v-form
                                            ref="form"
                                            v-model="valid"
                                            class="pa-3"
                                        >

                                            <v-text-field
                                                v-model="newPassword"
                                                :rules="passwordRules"
                                                label="New Password"
                                                required
                                                autocomplete='new'
                                                 prepend-icon="mdi-form-textbox-password"
                                                :type="value ? 'password' : 'text'"
                                                :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
                                                @click:append="() => (value = !value)"
                                            ></v-text-field>

                                            <v-card-actions class="text-center">
                                                <v-container fluid>
                                                    <v-row>
                                                        <v-col>
                                                        <v-btn
                                                            large
                                                            block
                                                            :disabled="!valid"
                                                            color="success"
                                                            class="mx-auto"
                                                            @click="sendPasswordReset()"
                                                        >
                                                            Submit
                                                        </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col>
                                                            <span class="grey--text text-darken-1">
                                                               Leave this page?
                                                            </span>
                                                            <span @click="leavePasswordReset()" class='signup-btn'>Cancel</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-card-actions>
                                        </v-form>
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="passwordIsReset">
                                    <v-card-title>Done!</v-card-title>
                                    <v-card-subtitle>Your password has been reset!</v-card-subtitle>
                                     <v-card-actions class="text-center">
                                        <v-container fluid>
                                            <v-row>
                                                <v-col>
                                                <v-btn
                                                    color="default"
                                                    class="mx-auto"
                                                    @click="leavePasswordReset()"
                                                >
                                                    Close
                                                </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-container>

            <v-dialog
                v-model="guestNotConfirmed"
                
                persistent
                width="300"
            >
                <v-card
                    color="primarycolor"
                    dark
                >
                    <v-card-text>
                       <div class="py-1"> Confirming guest ID...</div>
                    <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                    ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="processingReset"
                
                persistent
                width="300"
            >
                <v-card
                    color="primarycolor"
                    dark
                >
                    <v-card-text>
                        <div class="py-1">Processing password reset...</div>
                    <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                    ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-main>

        <!-- notification bar -->
        <v-snackbar v-model="snackbar.snackbar" :timeout="snackbar.timeout" top rounded="pill" elevation="24">
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar.snackbar = false">
                Close
            </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import axios from "axios";
import moment from 'moment';

export default {
    props:{
       token: String,
       guestId: Number
    },
    data: () => ({
        value:String,
        newPassword: String,
        guestNotConfirmed: Boolean,
        processingReset: Boolean,
        passwordIsReset: Boolean,
        snackbar:{
            snackbar:false,
            text:'',
            timeout: 5000
        },
        valid: false,
        passwordRules: [
            v => !!v || 'Password is required',
            v => v.length >= 8 || 'Password must 8 or more characters',
        ],
    }),
    created() {
    this.initialize();
    },
    methods: {
        initialize() {
            //init code goes here
            console.log("token: ", this.token);
             console.log("guestId: ", this.guestId);
            this.newPassword = '';
            this.guestNotConfirmed = true;
            this.processingReset = false;
            this.passwordIsReset = false;
            this.confirmGuestDetails();
        },
        confirmGuestDetails(){
            axios
            .get(window.Routes.get_confirm_guest_id_users_path(), {params: {format: 'json', guestId: this.guestId}})
            .then(response => {
                if (response.data.success){
                    this.newPassword = '';
                    this.guestNotConfirmed = false;
                }else{
                    this.newPassword = '';
                    this.guestNotConfirmed = true;
                    this.snackbar.text = response.message;
                    this.snackbar.snackbar = true;
                }
               
            })
            .catch(error => {
                console.log(error);
                this.snackbar.text = error.message;
                this.snackbar.snackbar = true;
            });
        },
        sendPasswordReset(){
            this.processingReset = true
            axios
                .post(window.Routes.post_password_reset_users_path(), {
                    format: 'json',
                    isocode: 'en',
                    token: this.token,
                    guestId: this.guestId,
                    password: this.newPassword
                })
                .then(response => {
                    this.processingReset = false;
                    this.passwordIsReset = true;
                    this.newPassword = '';
                    console.log(response);

                    if (response.data.success){
                        this.passwordIsReset = true;
                    }else{
                        this.passwordIsReset = false;
                        this.snackbar.text = response.data.message;
                        this.snackbar.snackbar = true;
                    }
                })
                .catch(error => {
                    this.processingReset = false;
                    this.passwordIsReset = false;
                    this.newPassword = '';
                    console.log(error);
                    this.snackbar.text = error.message;
                    this.snackbar.snackbar = true;
                    
                });
        },
        leavePasswordReset(){
            window.open(window.Routes.reservations_path(), '_self');    
        },   
    },
    
}
</script>
