
<template>
  <div v-if="hotels"> 
     <v-app-bar 
      dark 
      fixed 
      app>
        <div class="d-flex" @click="clearLocationDisplayName()" style="cursor:pointer;">
           <v-img :eager="true" contain height="50" width="50"  src="/icons/icon-152x152.png"></v-img>
           <v-toolbar-title class="d-flex px-3 mt-3" >  Lion Roars Bookings</v-toolbar-title>  
        </div>
        <v-spacer></v-spacer>
        <v-menu
          left
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="menuClick()"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-card color="primarycolor" dark depressed elevation="0" v-if="loggedInGuestId && loggedInGuestId.length" class="profilepanel rounded-0">
            <v-card-title class="text-subtitle-2 white--text">{{loggedInGuestName}}</v-card-title>
            <v-card-subtitle class="text-caption">Rands Earned:<br>ZAR {{loggedInGuestPoints}}.00</v-card-subtitle>
            <v-card-actions>
              <v-btn
                depressed elevation="0"
                color="white"
                text
                small
                @click="editProfile()"
              >
                Edit Profile
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-list v-if="loggedInGuestId && loggedInGuestId.length" dense class="profilelist">
            <v-list-item>
              <v-list-item-icon>
                <v-icon >mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="guestLogout()">Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon >mdi-help-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="assistanceDialog = true">Need Assistance</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon >mdi-application</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                 <v-list-item-title @click="$emit('showPrivacyPolicy', true)">Privacy Policy</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item style="cursor:default;">
              <v-list-item-icon>
                <v-icon >mdi-application</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="$emit('showTermsAndConditions', true);">Terms and Conditions</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Payments are secured by
                  <a href="https://www.peachpayments.com/#/products/payments" target="_blank">
                     <v-img src="peach_payment_logo_square.png"  width="140" style="margin: auto;"></v-img>
                  </a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-list v-if="!loggedInGuestId" dense class="profilelist">
            <v-list-item>
              <v-list-item-icon>
                <v-icon >mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="guestLogin()">Login</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon >mdi-help-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="assistanceDialog = true">Need Assistance</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item style="cursor:default;">
              <v-list-item-icon>
                <v-icon >mdi-application</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="$emit('showPrivacyPolicy', true);">Privacy Policy</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item style="cursor:default;">
              <v-list-item-icon>
                <v-icon >mdi-application</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="$emit('showTermsAndConditions', true);">Terms and Conditions</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Payments are secured by
                  <a href="https://www.peachpayments.com/#/products/payments" target="_blank">
                     <v-img src="peach_payment_logo_square.png" width="140" style="margin: auto;"></v-img>
                  </a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

        </v-menu>
    </v-app-bar>

    <guest-dialog @guestProcessing="isGuestProcessing = $event" v-bind:noCurrentUser="noCurrentUser" v-bind:guestEditProfile="guestEditProfile" @reverseEditFlag="guestEditProfile = $event" v-bind:guestDialogOpen="guestDialogOpen"  @reverseFlag="guestDialogOpen = $event" @setNoCurrentUser="noCurrentUser = $event" @snackBarTrigger="triggerSnackBar($event.text, $event.duration)" />
   
    <!--LOCATION PAGE-->
    <v-card color="transparent" elevation="0" class="pa-1" >
     
        <v-card v-if="!locationDisplayName && !hotelName" class="py-4"   >
          <v-card-title class="location-heading text-center d-block " >Choose a location</v-card-title>
          <v-card-text class="hide-scrollbar pa-0" >
            <v-list class='rounded-lg' >
              
              <v-list-item v-for="(hotelGroups,index) in hotels" :key="`${hotelGroups[0].location}_${index}`" @click="setLocation(hotelGroups)" light class="mb-1 py-0">
                <v-img :eager="true" :src="getLocationImageUrl(hotelGroups[0].location)" :aspect-ratio="2.5/1" class="location-img" gradient="to top, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0.1) 100%">
                  <v-list-item-content class="fill-height pa-5 align-center flex-nowrap flex-column">
                    <v-list-item-title class="location-title" >{{hotelGroups[0].location}}</v-list-item-title>
                    <v-list-item-subtitle color='white' class="location-hotels">{{getAvailableHotelCountText(hotelGroups.length)}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-img>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>

        <v-card v-if="locationDisplayName && !hotelName" class="py-4" >
          <v-card-title class="location-heading text-center d-block" >Choose a hotel</v-card-title>
          <v-card-text class="hide-scrollbar pa-0" >
            <v-list>
              <template v-for="(hotelGroups) in hotels">
                <template v-if="(hotelGroups[0].data.location == locationGroup && locationDisplayName == '') || hotelGroups[0].location == locationDisplayName || (locationDisplayName == '' && locationGroup == '')  ">
                    <v-list-item v-for="(hotel,index) in hotelGroups" :key="`${hotel.name}_${index}`" @click="setHotel(hotel)" class="mb-1 py-0" light>
                      <v-img :eager="true" :src="getHotelImageUrl(hotel.name)" :aspect-ratio="2.5/1" class="location-img" gradient="to top, rgba(0,0,0,0.55) 0%,rgba(0,0,0,0.3) 100%">
                        <v-list-item-content class="fill-height pa-5 align-center flex-nowrap flex-column">
                          <v-list-item-title class="location-title text-center" style="white-space: normal; color:white;" v-html="hotel.name"></v-list-item-title>
                          <!-- <v-chip color='white' class="location-hotels">Rooms Available</v-chip> -->
                        </v-list-item-content>
                      </v-img>
                    </v-list-item>
                </template>
              </template> 
            </v-list>
          </v-card-text>
        </v-card>
      

      <v-card color="transparent" elevation="0"  v-if="hotelName">
        <!--Location and Hotel-->
        <v-card-text class="hide-scrollbar pa-0">
          <v-row >
            <v-col cols="12"  >

              <v-btn
                large
                block  
                color="primarycolor"
                dark
                class="rounded-pill py-1"
                @click="clearLocationDisplayName()"
              >
                {{getLocationName()}}
              </v-btn>

            </v-col>  
        
            <v-col cols="12" >

              <v-btn class="rounded-pill py-1"
                block
                large
                color="primarycolor"
                dark
                @click="clearHotelName(), dialogHotel = !dialogHotel"
                v-html="getHotelName()"
              ></v-btn>
            </v-col>
          </v-row>
        
          
          <!--People-->
          
          <v-row>
            <v-col cols="6">
              <v-card class="text-center elevated pa-3 rounded-lg" :disabled="!hotelSelected">
                <h4 class="font-weight-light mb-2">Adults</h4>
                <div class="card-divider"></div>
                <div class="d-flex align-center justify-center">
                  <v-btn depressed icon color="primarycolor" @click="decrement('adult')">
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                  <span class="font-weight-light text-h4 mx-2">{{adultCount}}</span>
                  <v-btn depressed icon color="primarycolor" @click="increment('adult')">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </v-card>
            </v-col>
            
            <v-col cols="6" >
              <v-card class="text-center elevated pa-3 rounded-lg" :disabled="!hotelSelected || !hasAgeGroups">
                <h4 class="font-weight-light mb-2">Children</h4>
                <div class="card-divider"></div>
                <div class="d-flex align-center justify-center">
                  <v-btn depressed icon color="primarycolor" @click="dialogChildren = !dialogChildren">
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                  <span class="font-weight-light text-h4 mx-2">{{getTotalChildCount()}}</span>
                  <v-btn depressed icon  color="primarycolor" @click="dialogChildren = !dialogChildren">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </v-card>

              <v-dialog
                v-model="dialogChildren"
                width="500"
                scrollable
              >
                <v-card>
                  <v-toolbar
                  >
                    <v-toolbar-title>Select age group</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn depressed icon  @click="dialogChildren = !dialogChildren">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>

                  <template v-for="hotelGroups in hotels">
                    <template v-for="hotel in hotelGroups">
                      <template v-if="hotel.wbe_id == hotelId">
                        
                          <v-card class="text-center elevated pa-3 rounded-lg" v-for="(ageGroup, agIndex) in hotel.data.ageGroups" :key="agIndex">
                            <h4 class="font-weight-light mb-2">{{ageGroupDescription(ageGroup)}}</h4>
                    
                            <div class="d-flex align-center justify-center">
                              <v-btn depressed icon color="primarycolor"  @click="decrement('child',ageGroup.ageGroupCode, ageGroup.addBedRequired)">
                                <v-icon>mdi-minus</v-icon>
                              </v-btn>
                              <span class="font-weight-light text-h4 mx-2">{{getChildrenCount(ageGroup.ageGroupCode)}}</span>
                              <v-btn depressed icon color="primarycolor" @click="increment('child',ageGroup.ageGroupCode, ageGroup.addBedRequired)">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </div>
                            <div class="card-divider"></div>
                          </v-card>
                      </template>
                    </template>
                  </template>
                  <v-card-actions class="">
                    <v-btn color="secondarycolor" dark class="mx-auto rounded-pill py-1" @click="dialogChildren = !dialogChildren" >Done</v-btn>
                  </v-card-actions>
                </v-card>         
              </v-dialog>
              <!-- end -->
            </v-col>
          </v-row>
     
          <!--Dates-->
          
          <v-card class="rounded-lg elevated">
            <v-container fluid>
              <v-row>
                <v-col cols="12" >
                  <div class="text-center">
                    <h4 class="mb-1">Arrival and departure dates</h4>
                    <p  v-if="dates[0] && dates[1]"><v-icon small color="primarycolor">mdi-calendar-range</v-icon> {{displayCheckIn(dates)}} - {{displayCheckOut(dates)}}</p> 
                    <p v-else><v-icon small color="primarycolor">mdi-calendar-range</v-icon></p>         
                  </div>
                  <v-date-picker no-title
                    v-model="dates"
                    range
                    :min="getYesterdaysDate(hotelId)"
                    full-width
                    color="secondarycolor"
                    :disabled="!hotelSelected"
                    :show-adjacent-months="true"
                  ></v-date-picker>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
      
      
          <v-row>
            <v-col class="pt-0 mt-3" cols="12" >
              <div class="text-center elevated white rounded-lg">
                <!--<h4>Promotion Code</h4>-->
              <v-text-field
                      class="promo-input text-center"
                      v-model="promocode"
                      hide-details="auto"
                      outlined
                      placeholder="Add promo code"
                      v-if="hotelSelected"
                    ></v-text-field>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" >
              <v-btn  
                class="search-button"
                dark
                color="primarycolor"
                block
                rounded
                large
              
                :loading="loading == isSearching"
                :disabled="(loading == isSearching) || !(hotelId && dates[0] && dates[1] )"
                @click="getSearchResults()">
                Search Availability
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>

    <v-dialog
      v-model="signUpDialog"
      max-width="750"
      class="signup"
      persistent
      >
      <v-card
          class="p3"
          :style="{textAlign: 'center'}"
      >
       <v-card-subtitle  class="no-thanks"><a @click="signUpDialog = !signUpDialog" color="white">X</a></v-card-subtitle>
        <v-img
           class="mx-auto"
          src="/imgs/Modal-background-2.jpg"
          width="750"
        ></v-img>
        <div 
        class="modalhalf"
        >
          <v-card-actions>
            <v-btn
              large
              block
              color="primarycolor"
              @click="signUpDialog = !signUpDialog; guestLogin()"
            >
              Login or Sign up
            </v-btn>
          </v-card-actions>
          <v-card-subtitle color="grey lighten-1" class="modal-title"><small>Terms and conditions apply. By signing up you consent to receive marketing materials which you may opt out of at any time.</small></v-card-subtitle>
          <v-card-subtitle color="grey lighten-1"  class="modal-title no-thanks-text"><a @click="signUpDialog = !signUpDialog"><small>No, I'm OK for now, thanks.</small></a></v-card-subtitle>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isSearching"
      
      persistent
      width="300"
      >
      <v-card
          color="primarycolor"
          dark
      >
          <v-card-text>
          <div class="py-1">Finding available rooms...</div>
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
          </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isGuestProcessing"
      
      persistent
      width="300"
      >
      <v-card
          color="primarycolor"
          dark
      >
          <v-card-text>
          <div class="py-1">Processing guest data...</div>
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
          </v-card-text>
      </v-card>
    </v-dialog>
    <assistance-dialog v-bind:assistanceDialog="assistanceDialog"  @reverseFlag="assistanceDialog = $event" />
     <!-- notification bar -->
    <v-snackbar v-model="snackbar.snackbar" :timeout="snackbar.timeout" top rounded="pill" elevation="24">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar.snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

import moment from 'moment';

export default {
  props: {
    hotels: Array,
    queryParams: Object,
    isSearching: Boolean,
    externalHotelParams: Object
  },
  data: () => ({
    version: '1.1',
    assistanceDialog:false,
    initialLoadDone: false,
    searchParams: Object,
    locationGroup: String,
    locationDisplayName: String,
    hotel: Object,
    hotelId: Number,
    hotelName: String,
    adultCount: Number,
    children1Count: Number,
    children2Count: Number,
    children3Count: Number,
    children4Count: Number,
    addCotCount: Number,
    addBedCount: Number,
    promocode: String,
    dates: Array,
    loading: true,
    hotelSelected: false,
    hasAgeGroups: false,

    signUpDialog:false,

    guestDialogOpen: false,
    guestEditProfile: false,
    isGuestProcessing: false,
    noCurrentUser: true,

    loggedInGuestId:String,
    loggedInGuestName:String,
    loggedInGuestPoints:String,
    loggedInGuestPromocodes:String,

    dialogLocation: false,
    dialogHotel: false,
    dialogChildren: false,

    snackbar:{
      snackbar:false,
      text:'',
      timeout: 3000
    },
  
  }),
  computed: {
    // a computed getter
    // loggedInGuestId() {
    //   // `this` points to the vm instance
    //   return  (localStorage.getItem('guestId') && localStorage.getItem('guestId').length > 0) ? localStorage.getItem('guestId') : null
    // },
   
  },
  mounted() {
    window.onpopstate = event => {
       if (this.locationDisplayName != '' && this.locationDisplayName != null){
          this.locationDisplayName = '';
          this.hotelName = '';
       }
       
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    hotelId: function(newVal, oldVal){
      if (this.initialLoadDone){
        this.adultCount = 1;
        this.children1Count = 0;
        this.children2Count = 0;
        this.children3Count = 0;
        this.children4Count = 0;  
        this.addCotCount = 0;
        this.addBedCount = 0;  
      }else{
        this.initialLoadDone = true;
      }
    }
  },
  methods: {
    initialize() {
      //init method code goes here
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
      });
      console.log("Hotels: ",this.hotels);

      this.locationGroup = this.queryParams.location;
      this.locationDisplayName = this.queryParams.locationDisplayName;
      this.hotelId = this.queryParams.hotelId;
      this.hotelName = this.queryParams.hotelName;
      this.adultCount = this.queryParams.adultCount;
      this.children1Count = this.queryParams.children1Count;
      this.children2Count = this.queryParams.children2Count;
      this.children3Count = this.queryParams.children3Count;
      this.children4Count = this.queryParams.children4Count;
      this.addCotCount = this.queryParams.addCotCount;
      this.addBedCount = this.queryParams.addBedCount;
      this.promocode = this.queryParams.promocode;
      this.dates = [this.queryParams.from, this.queryParams.to];

      if ((this.hotelId == undefined || this.hotelId == null || this.hotelId == '' ) && this.externalHotelParams){
        this.locationGroup = this.externalHotelParams.location;
        this.locationDisplayName = this.externalHotelParams.locationDisplayName;
        this.hotelId = this.externalHotelParams.hotelId;
        this.hotelName = this.externalHotelParams.hotelName;

        this.adultCount = this.externalHotelParams.adultCount;
        this.children1Count = this.externalHotelParams.children1Count;
        this.children2Count = this.externalHotelParams.children2Count;
        this.children3Count = this.externalHotelParams.children3Count;
        this.children4Count = this.externalHotelParams.children4Count;
        this.addCotCount = this.externalHotelParams.addCotCount;
        this.addBedCount = this.externalHotelParams.addBedCount;
        this.promocode = this.externalHotelParams.promocode;
        this.dates = [this.externalHotelParams.from, this.externalHotelParams.to];
        
        if (this.externalHotelParams.children2Count > 0){
          this.dialogChildren = true;
        }
        //if location is selected add a history point to click back to
        history.pushState(null, document.title, location.href);
      }


      this.setAgeGroupsAndHotelSelected();
      this.menuClick();

      if (!this.queryParams.hotelName){
        this.signUpDialog = this.noCurrentUser;
      }
     

      if (this.hotelName.length > 0){
        this.$emit('backgroundImage', this.getHotelBackgroundImageUrl(this.hotelName))
      }

    },
    menuClick(){
      this.loggedInGuestId = (localStorage.getItem('guestId') && localStorage.getItem('guestId').length > 0) ? localStorage.getItem('guestId') : null;
      this.loggedInGuestName = (localStorage.getItem('guestName') && localStorage.getItem('guestName').length > 0) ? localStorage.getItem('guestName') : null;
      this.loggedInGuestPoints = (localStorage.getItem('pointBalance') && localStorage.getItem('pointBalance').length > 0) ? localStorage.getItem('pointBalance') : null;
      this.loggedInGuestPromocodes = (localStorage.getItem('guestPromocodes') && localStorage.getItem('guestPromocodes').length > 0) ? localStorage.getItem('guestPromocodes') : null;
      console.log("check if logged in: ", this.loggedInGuestId)
      if (this.loggedInGuestId == null){
        this.noCurrentUser = true;
      }else{
        this.noCurrentUser = false;
      }
    },
    setAgeGroupsAndHotelSelected(){
      if (this.hotelId > 0){
        this.hotelSelected = true;  //hotel has been selected
        this.hotels.forEach(hotelGroups => {
          hotelGroups.forEach(hotel => {
            if (hotel.wbe_id == this.hotelId ){
              console.dir("ageGroups: ",hotel.data.ageGroups );
              if (hotel.data.ageGroups.length > 1){
                this.hasAgeGroups = true; //hotel has age groups
              }else{
                this.hasAgeGroups = false; //hotel has No age groups
              }
            }
          }) 
        });
      }else{
        this.hotelSelected = false;
        this.hasAgeGroups = false;
      } 
    },
    getLocationImageUrl(locationName){
      let imageName = '';
      if (locationName == 'Plettenberg Bay'){
        imageName = 'plett-beach.jpg'
      }else if (locationName == "Franschhoek"){
        imageName = 'franschhoek.jpg'
      }else if (locationName == "Port Elizabeth"){
        imageName = 'pe.jpg'
      }else if (locationName == "Cape Town"){
        imageName = 'capetown.jpg'
      }else if (locationName == "Greater Addo Region"){
        imageName = 'addo.jpg'
      }
      return `hotelImg/${imageName}`
    },
    getHotelImageUrl(hotelName){
       let imageName = '';
      if (hotelName == 'The Robberg Beach Lodge<br>and Cottage Pie Beach Lodge'){
        imageName = 'robberg-beach-lodge.jpg'
      }else if (hotelName == "Franschhoek Boutique Hotel"){
        imageName = 'franschhoek-boutique-hotel.jpg'
      }else if (hotelName == "Singa Lodge"){
        imageName = 'singa-lodge.jpg'
      }else if (hotelName == "Old Bank"){
        imageName = 'old-bank-hotel-interior.jpg'
      }else if (hotelName == "Kloof Street Hotel"){
        imageName = 'kloof-street-hotel.jpg'
      }else if (hotelName == "The Three Boutique Hotel"){
        imageName = 'the-three-boutique-hotel.jpg'
      }else if (hotelName == "Bukela Game Lodge"){
        imageName = 'bukela-game-lodge-interior.jpg'
      }else if (hotelName == "Hlosi Game Lodge"){
        imageName = 'hlosi-game-lodge.jpg'
      }else if (hotelName == "Plett Quarter Hotel"){
        imageName = 'plett-quarter.jpg'
      }
      return `hotelImg/${imageName}`
    },
    getLocationBackgroundImageUrl(locationName){
      let imageName = '';
      if (locationName == 'Plettenberg Bay'){
        imageName = 'plett-beach.jpg'
      }else if (locationName == "Franschhoek"){
        imageName = 'franschhoek.jpg'
      }else if (locationName == "Port Elizabeth"){
        imageName = 'pe.jpg'
      }else if (locationName == "Cape Town"){
        imageName = 'cape-town-background.jpg'
      }else if (locationName == "Greater Addo Region"){
        imageName = 'addo-background.jpg'
      }
      return `hotelImg/${imageName}`
    },
    getHotelBackgroundImageUrl(hotelName){
       let imageName = '';
      if (hotelName == 'The Robberg Beach Lodge<br>and Cottage Pie Beach Lodge'){
        imageName = 'robberg-beach-lodge-background.jpg'
      }else if (hotelName == "Franschhoek Boutique Hotel"){
        imageName = 'franschhoek-background.jpg'
      }else if (hotelName == "Singa Lodge"){
        imageName = 'singa-background.jpg'
      }else if (hotelName == "Old Bank"){
        imageName = 'old-bank-background.jpg'
      }else if (hotelName == "Kloof Street Hotel"){
        imageName = 'kloof-street-background.jpg'
      }else if (hotelName == "The Three Boutique Hotel"){
        imageName = 'three-boutique-background.jpg'
      }else if (hotelName == "Bukela Game Lodge"){
        imageName = 'bukela-background.jpg'
      }else if (hotelName == "Hlosi Game Lodge"){
        imageName = 'hlosi-backgorund.jpg'
      }else if (hotelName == "Plett Quarter Hotel"){
        imageName = 'plett-quarter-background.jpg'
      }
      return `hotelImg/${imageName}`
    },
    getAvailableHotelCountText(hotelCount){
      if (hotelCount > 1){
        return `${hotelCount} Hotels available`
      }else{
        return `${hotelCount} Hotel available`
      }
    },
    getLocationName(){
      if (this.locationDisplayName == null || this.locationDisplayName == '' ){
        return 'Choose a location'
      }else{
        return this.locationDisplayName
      }
    },
    clearLocationDisplayName(){
      
      if (this.locationDisplayName != '' && this.locationDisplayName != null){
        history.back();
      }
      this.locationDisplayName = '';
      this.hotelName = '';
    },
    clearHotelName(){
      this.hotelName = '';
    },
    getHotelName(){
      
      if (this.hotelName == null || this.hotelName == ''){
        return 'Choose a hotel'
      }else{
        return this.hotelName
      }
    },
    setLocation($hotelGroup){
      if ($hotelGroup.length > 1){
        this.locationDisplayName = $hotelGroup[0].location;
        this.locationGroup = '';
        this.hotelName = '';
        this.hotelId = '';
        this.dialogLocation = false;
        this.dialogHotel = true;
        this.hotelSelected = false;

         this.$emit('backgroundImage', this.getLocationBackgroundImageUrl(this.locationDisplayName))
      }else{
        this.locationDisplayName = $hotelGroup[0].location;
        this.locationGroup = $hotelGroup[0].data.city;
        this.hotelName = $hotelGroup[0].name;
        this.hotelId = $hotelGroup[0].wbe_id;
        this.dialogLocation = false;
        this.hotelSelected = true;

       this.$emit('backgroundImage', this.getHotelBackgroundImageUrl(this.hotelName))
      }
      this.setAgeGroupsAndHotelSelected();
      
      console.log("location name:", this.locationDisplayName)

      //if location is selected add a history point to click back to
      history.pushState(null, document.title, location.href);
    },
    setHotel($hotel){
      this.hotelName = $hotel.name;
      this.hotelId = $hotel.wbe_id;
      this.locationGroup = $hotel.data.city;
      this.locationDisplayName = $hotel.location;
      this.dialogHotel = false;
      this.hotelSelected = true;
      this.setAgeGroupsAndHotelSelected();
      this.$emit('backgroundImage', this.getHotelBackgroundImageUrl(this.hotelName))

      if (this.hotelId === 19384 && new Date() <= new Date("2023-11-17")){
        this.dates = ['2023-11-17','2023-11-17']
      }
    },
     decrement($type, ageGroupCode='', addBedRequired=''){
      if ($type == 'adult' && this.adultCount > 1){
        this.adultCount -= 1
      }else if ($type == 'child' && ageGroupCode == 'C1' && this.children1Count > 0){
        this.children1Count -= 1
      }else if ($type == 'child' && ageGroupCode == 'C2' && this.children2Count > 0){
        this.children2Count -= 1
      }else if ($type == 'child' && ageGroupCode == 'C3' && this.children3Count > 0){
        this.children3Count -= 1
      }else if ($type == 'child' && ageGroupCode == 'C4' && this.children4Count > 0){
        this.children4Count -= 1
      }
      if (addBedRequired == 'Cot'){
        this.addCotCount -= 1;
      }else if (addBedRequired == 'Bed'){
        this.addBedCount -= 1;
      }
    },
    increment($type, ageGroupCode='', addBedRequired=''){
      if ($type == 'adult'){
        this.adultCount += 1
      }else if ($type == 'child' && ageGroupCode == 'C1'){
        this.children1Count += 1
      }else if ($type == 'child' && ageGroupCode == 'C2'){
        this.children2Count += 1
      }else if ($type == 'child' && ageGroupCode == 'C3'){
        this.children3Count += 1
      }else if ($type == 'child' && ageGroupCode == 'C4'){
        this.children4Count += 1
      }
      if (addBedRequired == 'Cot'){
        this.addCotCount += 1;
      }else if (addBedRequired == 'Bed'){
        this.addBedCount += 1;
      }
    },
    ageGroupDescription(ageGroup){
      if (ageGroup.ageGroupCode == 'C1'){
        return `Baby: ${ageGroup.ageFrom} - ${ageGroup.ageTo} years`
      }else if (ageGroup.ageGroupCode == 'C2'){
        return `Child: ${ageGroup.ageFrom} - ${ageGroup.ageTo} years`
      }else if (ageGroup.ageGroupCode == 'C3'){
        return `Teen: ${ageGroup.ageFrom} - ${ageGroup.ageTo} years`
      }else {
        return ``
      }
    },
    getTotalChildCount(){
      return this.children1Count + this.children2Count + this.children3Count + this.children4Count;
    },
    getChildrenCount(ageGroupCode=''){
      if (ageGroupCode == 'C1'){
        return this.children1Count;
      }else if (ageGroupCode == 'C2'){
        return this.children2Count;
      }else if (ageGroupCode == 'C3'){
        return this.children3Count;
      }else if (ageGroupCode == 'C4'){
        return this.children4Count;
      }
    },
    getYesterdaysDate($hotelId){
      let yesterday = new Date();

      // set specific start date for Plett Quarter
      if ($hotelId === 19384 && new Date() <= new Date("2023-11-17")){
        yesterday = new Date("2023-11-17")
      }

      let dd = String(yesterday.getDate()).padStart(2, '0');
      let mm = String(yesterday.getMonth() + 1).padStart(2, '0'); 
      let yyyy = yesterday.getFullYear();

      return yyyy + '-' + mm + '-' + dd;
    },
    getCheckIn($dates){
      if ($dates[0] > $dates[1]){
        return $dates[1]
      }else{
        return $dates[0]
      }
    },
    getCheckOut($dates){
      if ($dates[0] > $dates[1]){
        return $dates[0]
      }else{
        return $dates[1]
      }
    },
    displayCheckIn($dates){
      if ($dates[0] > $dates[1]){
        return moment($dates[1]).format('DD MMM YYYY')
      }else{
        return moment($dates[0]).format('DD MMM YYYY')
      }
    },
    displayCheckOut($dates){
      if ($dates[0] > $dates[1]){
        return moment($dates[0]).format('DD MMM YYYY')
      }else{
        return moment($dates[1]).format('DD MMM YYYY')
      }
    },
    getSearchResults(){

      if (this.promocode == '' && localStorage.getItem('guestId') && localStorage.getItem('guestId').length > 0 && localStorage.getItem('guestPromocodes') && localStorage.getItem('guestPromocodes').length > 0){
        this.promocode = localStorage.getItem('guestPromocodes');
      }else if (this.promocode == '' && localStorage.getItem('guestId') && localStorage.getItem('guestId').length > 0){
        this.promocode = 'BOOKDIRECT';
      }else if (this.promocode == 'BOOKDIRECT' && !localStorage.getItem('guestId') ){
        this.triggerSnackBar('Promo code restriction. You must be logged in to use this promo code!', 5000)
        console.log("NOT ALLOWED");
        this.promocode = '';
        return;
      }

      this.searchParams = {
        hotelId: this.hotelId,
        hotelName: this.hotelName,
        location: this.locationGroup,
        locationDisplayName: this.locationDisplayName,
        type: 'standardroom',
        countrooms: 1,
        occupancy: [this.adultCount,this.children1Count,this.children2Count,this.children3Count,this.children4Count,this.addBedCount,this.addCotCount],
        adultCount: this.adultCount,
        children1Count: this.children1Count,
        children2Count: this.children2Count,
        children3Count: this.children3Count,
        children4Count: this.children4Count,
        addBedCount: this.addBedCount,
        addCotCount: this.addCotCount,
        from: this.getCheckIn(this.dates),
        to: this.getCheckOut(this.dates),
        instances: [this.hotelId],
        promocode: this.promocode.toUpperCase().replace('#',''),
        
      }
      this.$emit('getSearchResults', this.searchParams)
    },
    guestLogin(){
      this.guestDialogOpen = true;
    },
    editProfile(){
      this.guestEditProfile = true;
      this.guestDialogOpen = true;  
    },
    guestLogout(){
      this.triggerSnackBar(`Goodbye ${this.loggedInGuestName}. You have logged out.`, 5000)
      localStorage.clear("guestId");
      localStorage.clear("guestName");
      localStorage.clear("pointBalance");   
      this.menuClick();
      this.promocode = '';
    },
    triggerSnackBar(snackbarText, snackBarTimeout=3000){
      this.snackbar.text = snackbarText;
      this.snackbar.snackbar = true;
      this.snackbar.timeout = snackBarTimeout;
    }
  }
};
</script>
