<template>
    <v-main >
        <v-container style="max-width:800px;">
            <v-app-bar dark fixed  app>      
                <v-spacer></v-spacer>
                <v-toolbar-title>Checking Payment</v-toolbar-title>  
            </v-app-bar>
            <v-dialog
                v-model="dialogLoading"
                hide-overlay
                persistent
                width="300"
                >
                <v-card
                    color="primarycolor"
                    dark
                >
                    <v-card-text>
                        <div class="py-1">{{modalTitle}}</div>
                        <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-sheet
                :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
                class=""
                >
                <v-skeleton-loader
                    class="mx-auto"
                    type=" table-heading, list-item-three-line,list-item-three-line,list-item-three-line, table-tfoot"
                ></v-skeleton-loader>
            </v-sheet>
        </v-container>
    </v-main>
</template>

<script>
import axios from "axios";
export default {
    inject: {
      theme: {
        default: { isDark: false },
      },
    },
    props:{
       gatewayResponse: Object,
    },
    data: () => ({
        dialogLoading: true,
        modalTitle: String
    }),
    created() {
    this.initialize();
    },
    methods: {
        initialize() {
            //init code goes here
            console.log(this.gatewayResponse);
            if (this.gatewayResponse.basketId != undefined){
                this.processBasketStatus()                  
            }else{
                this.updateTransactionStatus()
            }
            
            this.modalTitle = 'Fetching payment information...'
        },
        processBasketStatus(){
            axios
            .post(window.Routes.post_process_basket_status_payments_path(), {
                format: 'json',
                basketId: this.gatewayResponse.basketId
            })
            .then(response => {
                if (response.data.success){
                    //if successful - go fetch reservation details and update basket status
                    this.modalTitle = 'Fetching reservation information...'
                    return axios
                    .post(window.Routes.post_reservation_details_payments_path(), {
                    format: 'json',
                    basketId: response.data.basketId
                    })
                    .then(response2 => {
                        if (response.data.success){
                            window.open(window.Routes.checkout_complete_reservations_path() + '?basketId=' + response.data.basketId , '_self');
                        }else{
                            window.open(window.Routes.checkout_complete_reservations_path() + '?basketId=' + response.data.basketId  + '&errorMessage=' + response2.data.error.message , '_self');
                        }
                    })                
                    .catch(error2 => {
                       console.log(error2);
                    });
                }else{
                    console.log("Error: ",response);
                }
            })
            .catch(error => {
               console.log(error);
            });
        },
        updateTransactionStatus(){
            axios
            .post(window.Routes.post_transaction_status_payments_path(), {
                format: 'json',
                resourcePath: this.gatewayResponse.resourcePath,
                checkoutId: this.gatewayResponse.id,
            })
            .then(response => {
                if (response.data.success){
                    //if successful - go fetch reservation details and update basket status
                    this.modalTitle = 'Fetching reservation information...'
                    return axios
                    .post(window.Routes.post_reservation_details_payments_path(), {
                    format: 'json',
                    basketId: response.data.basketId
                    })
                    .then(response2 => {
                        if (response.data.success){
                            window.open(window.Routes.checkout_complete_reservations_path() + '?checkoutId=' + this.gatewayResponse.id + '&transactionNumber=' + response.data.transactionNumber, '_self');
                        }else{
                            window.open(window.Routes.checkout_complete_reservations_path() + '?checkoutId=' + this.gatewayResponse.id + '&transactionNumber=' + response.data.transactionNumber + '&errorMessage=' + response2.data.errorMessage.message , '_self');
                        }
                    })                
                    .catch(error2 => {
                        window.open(window.Routes.checkout_complete_reservations_path() + "?checkoutId=" + this.gatewayResponse.id + "&errorMessage=Something went wrong. Couldn't retrieve reservation details.", '_self');
                    });
                }else{
                    window.open(window.Routes.retry_checkout_reservations_path() + '?checkoutId=' + this.gatewayResponse.id + '&transactionNumber=' + response.data.transactionNumber + '&errorMessage=' + response.data.errorMessage.message , '_self');
                }
            })
            .catch(error => {
               console.log("Error: ", error);
               window.open(window.Routes.retry_checkout_reservations_path() + "?checkoutId=" + this.gatewayResponse.id + "&errorMessage=Something went wrong. Couldn't retrieve transaction status.", '_self');
            });
        },
        

    },
    

}
</script>
