<template>
    <div>
         <v-main >
            <!-- Provides the application the proper gutter -->
            <v-container style="max-width:800px;"> 
                <v-app-bar dark fixed  app>
                    <div class="d-flex" @click="leaveCheckoutDialog = !leaveCheckoutDialog" style="cursor:pointer;">
                       <v-img :eager="true" contain height="50" width="50"  src="/icons/icon-152x152.png"></v-img>
                       <v-toolbar-title class="d-flex px-3 mt-3" >  Lion Roars Bookings</v-toolbar-title>
                    </div>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>Payment Failed</v-toolbar-title>  
                </v-app-bar>
                <v-card flat color="transparent" >
                    <v-card-text class="hide-scrollbar pa-0">
                        <v-row>
                            <v-col cols="12" class='pt-0' v-if="basket && basket.id">
                                 <v-card class="booking-details elevated rounded-lg" flat>
                                    <v-card-title class="text-h5">
                                    Reservation
                                    </v-card-title>
                                    <v-card-subtitle>
                                        <strong>Status:</strong> Payment failed <v-icon color="red">mdi-alert-outline</v-icon>
                                        <br>
                                        <strong>Reason:</strong> {{codeGroup}}: {{codeDescription}}
                                    </v-card-subtitle>
                                    <v-card-text>
                                        <div class="booking-subdetails justify-space-between">
                                            <div>
                                                <span><v-icon small color="primarycolor">mdi-calendar-range</v-icon> {{from}} - {{to}}</span>
                                            </div>
                                            <div>
                                                <span><v-icon color="primarycolor" small>mdi-bed</v-icon> {{numberOfNightsText}}</span>
                                            </div>
                                             <div v-if="basket.booker.company && basket.booker.company.length > 0">
                                                <span><v-icon small color="primarycolor">mdi-account-settings</v-icon> {{basket.booker.company}}</span>
                                            </div>
                                            <div>
                                                <span><v-icon small color="primarycolor">mdi-account-settings</v-icon> {{basket.booker.firstname}} {{basket.booker.lastname}}</span>
                                            </div>
                                           <div>
                                                <span><v-icon color="primarycolor" small>mdi-email</v-icon> {{basket.booker.email}}</span>
                                            </div>
                                        </div>

                                    </v-card-text>
                                    <v-list
                                        subheader
                                    >
                                        <v-subheader class="text-h6" v-html="hotelName"></v-subheader>
                                        <div  v-for="(item,index) in basket.basketItems" :key="index" >
                                          <v-list-item>
                                              <v-list-item-content class="text-left">
                                                  <v-list-item-title v-text="item.categoryDesc"></v-list-item-title>
                                                  <v-list-item-subtitle v-text="displayGuestNames(item.guests)"></v-list-item-subtitle>
                                              </v-list-item-content>
                                              <v-list-item-action>
                                                  <v-list-item-title v-text="roomPriceFormat(item.amount)"></v-list-item-title>
                                              </v-list-item-action>
                                          </v-list-item>
                                          <!--<div v-if="item.bookableItems && item.bookableItems.length > 0">-->
                                          <!--  <v-list-item v-for="(bookableItem, index1) in item.bookableItems" :key="index1">-->
                                          <!--    <v-list-item-content class="text-left">-->
                                          <!--      <v-list-item-title v-text="bookableItem.description"></v-list-item-title>-->
                                          <!--    </v-list-item-content>-->
                                          <!--    <v-list-item-action>-->
                                          <!--      <v-list-item-title v-text="roomPriceFormat(bookableItem.amount)"></v-list-item-title>-->
                                          <!--    </v-list-item-action>-->
                                          <!--  </v-list-item>-->
                                          <!--</div>-->
                                        </div>
                                        <div v-if="basket.basketItems[0].bookableItems && basket.basketItems[0].bookableItems.length > 0">
                                            <v-subheader class="text-h6">Extras</v-subheader>
                                            <v-list-item v-for="(bookableItem, index1) in basket.basketItems[0].bookableItems" :key="index1">
                                              <v-list-item-content class="text-left">
                                                <v-list-item-title v-text="bookableItem.description"></v-list-item-title>
                                              </v-list-item-content>
                                              <v-list-item-action>
                                                <v-list-item-title v-text="roomPriceFormat(bookableItem.amount)"></v-list-item-title>
                                              </v-list-item-action>
                                            </v-list-item>
                                          </div>
                                        <v-list-item v-if="refundable"> 
                                            <v-list-item-content class="text-left">
                                                <v-list-item-title>Refundable Booking</v-list-item-title>
                                                <v-list-item-subtitle></v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-list-item-title v-text="getRefundPremium()"></v-list-item-title>
                                            </v-list-item-action>
                                        </v-list-item>   
                                        <v-list-item>
                                            <v-list-item-content class="text-left">
                                                <v-list-item-title><strong>Amount Payable</strong></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-list-item-title ><strong>{{roomTotalPriceFormat(basket.basketItems)}}</strong></v-list-item-title>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-card flat class="mt-3 py-2 elevated rounded-lg"  v-if="basket && basket.id">
                            <v-card-actions >
                                <v-btn rounded color="success" @click="retryPayment"  :loading="isSearching"  class="mx-auto">Retry payment</v-btn>
                            </v-card-actions>
                             <v-card-actions >
                                 <v-btn text rounded color="success" @click="leaveCheckoutDialog = !leaveCheckoutDialog" class="mx-auto">Make another reservation</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-card-text>
                </v-card>
                <v-dialog
                    v-model="leaveCheckoutDialog"
                    persistent
                    max-width="290"
                    >
                    <v-card rounded >
                        <v-card-title class="headline">
                        Leaving Retry?
                        </v-card-title>
                        <v-card-text>Once you leave this page your reservation details will be reset.<br>Are you sure you want to leave?</v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="green darken-1"
                                text
                                @click="leaveCheckoutDialog = false"
                            >
                                No
                            </v-btn>
                            <v-btn
                                color="green darken-1"
                                text
                                @click="startAgain"
                            >
                                Yes
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-container>
         </v-main>

           <!-- notification bar -->
        <v-snackbar v-model="snackbar.snackbar" :timeout="snackbar.timeout" top rounded="pill" elevation="24">
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar.snackbar = false">
                Close
            </v-btn>
            </template>
        </v-snackbar>

         <v-dialog
            v-model="isSearching"
            
            persistent
            width="300"
            >
            <v-card
                color="primarycolor"
                dark
            >
                <v-card-text>
                     <div class="py-1">Preparing checkout for retry...</div>
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
                </v-card-text>
            </v-card>
            </v-dialog>

    </div>
</template>

<script>
import axios from "axios";
import moment from 'moment';
export default {
    props:{
       gatewayResponse: Object,
    },
    data: () => ({
        transaction: Object,
        basket: Object,
        basketGuid: String,
        transactionNumber: String,
        errorMessage: String,
        isSearching: false,
        snackbar:{
            snackbar:false,
            text:'',
            timeout: 3000
        },
        numberOfNightsText:String,
        hotelName: String,
        from: String,
        to: String,
        codeGroup: String,
        codeDescription: String,
        leaveCheckoutDialog: false,
        refundable: Boolean,
        refund_premium: Number
    }),
    created() {
    this.initialize();
    },
    methods: {
        initialize() {
            //init code goes here
            console.log(this.gatewayResponse);
           this.transaction = {}
           this.errorMessage = this.gatewayResponse.errorMessage
           console.log("Errormessage: ",this.errorMessage );
           if (this.gatewayResponse.errorMessage && this.gatewayResponse.errorMessage.length > 0){
                this.errorMessage = this.gatewayResponse.errorMessage
           }else{
                this.errorMessage = {}
           }
           if (this.gatewayResponse.transactionNumber && this.gatewayResponse.transactionNumber.length > 0){
                this.transactionNumber = this.gatewayResponse.transactionNumber
                this.getTransactionAndBasket()
           }
           
        },
        getTransactionAndBasket(){
            return axios
            .get(window.Routes.get_transaction_and_basket_payments_path(), {
                params:{
                    format: 'json',
                    transactionNumber: this.transactionNumber,
                }
            })
            .then(response => {
                if (response.data.success){
                    this.transaction = response.data.transaction.data.payment_status[response.data.transaction.data.payment_status.length - 1];
                    this.basket = response.data.basket.data;
                    this.basketGuid = response.data.basket.basket_id;
                    this.codeGroup = response.data.transaction.code_group;
                    this.codeDescription = response.data.transaction.code_description;
                    this.refundable = response.data.basket.refundable;
                    this.refund_premium = response.data.basket.refund_premium;
                    this.assignBasketVariables();

                    console.log("Tranaction: ", response.data.transaction);
                    console.log("Basket: ",response.data.basket);
                }else{
                    console.log(response.data.error);
                    this.snackbar.text = response.data.error.message;
                    this.snackbar.snackbar = true;
                    this.isSearching = false;
                }
            })
            .catch(error => {
                console.log(error);
                this.snackbar.text = 'Error occurred when trying to fecth transaction status.';
                this.snackbar.snackbar = true;
                this.isSearching = false;
            });
        },
        assignBasketVariables(){
                this.hotelName = this.basket.basketItems[0].mpename;
                this.from = this.displayCheckIn(this.basket.basketItems[0].from);
                this.to = this.displayCheckOut(this.basket.basketItems[0].to);
                this.numberOfNightsText = this.numberOfNightsShortText(this.basket.basketItems[0].from, this.basket.basketItems[0].to);

               
        },
         numberOfNightsShortText(from, to){
            let nightCount = this.calcNights(from,to);
            if (nightCount > 1){
                return `${nightCount} Nights`
            }else{
                return `${nightCount} Night`
            }
        },
        calcNights(from,to){
            let _to = moment(to);
            let _from = moment(from);
            return _to.diff(_from,'days');
        },
         displayGuestNames(guests){
            let guestArray = [];
            guests.forEach(element => {
                guestArray.push(element.firstname)
            });
            if (guestArray.length > 1){
                return `Guests: ${guestArray.join(', ')}`;
            }else{
                return `Guest: ${guestArray.join(', ')}`;
            }
        }, 
        roomPriceFormat(amount){
            let price = amount.numeric / 100;
            let currency = amount.currency;
            return `${currency} ${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
        },
        roomTotalPriceFormat(items){
            let currency = items[0].amount.currency;
            let price = 0;
            items.forEach(item => {
                price += item.amount.numeric / 100;
                if (item.bookableItems != undefined && item.bookableItems.length > 0){
                    item.bookableItems.forEach(bookableItem => {
                        price += bookableItem['amount']['numeric'] / 100
                    })
                }
            });
            if (this.refundable){
                price +=  this.refund_premium;
            }
            return `${currency} ${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
        },
         getRefundPremium(){
            return `ZAR ${this.refund_premium.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
        },
        displayCheckIn($date){
            return moment($date).format('DD MMM YYYY')
        },
        displayCheckOut($date){
            return moment($date).format('DD MMM YYYY')
        }, 
        retryPayment(){
            this.isSearching = true
            console.dir(this.transaction)
            console.dir(this.basketGuid)
            axios
            .post(window.Routes.post_prepare_checkout_payments_path(), {
                format: 'json',
                basketId: this.basketGuid
            })
            .then(response => {
                window.open(response.data.checkout_path , '_self');
            })
            .catch(error => {
                console.log(error);
                this.snackbar.text = 'Error occurred when preparing checkout.';
                this.snackbar.snackbar = true;
                this.isSearching = false;
            });
        },
        startAgain(){
            window.open(window.Routes.reservations_path(), '_self');
        }

    },
    

}
</script>
