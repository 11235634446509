<template>
    <div class="text-center">
        <v-dialog
        v-model="dialog"
        max-width="500"
        scrollable
        >
           <v-card class="p3">  
                <v-toolbar
                    flat
                    dark
                >
                    <div class="d-flex">
                        <v-img :eager="true" contain height="50" width="50"  src="/icons/icon-152x152.png"></v-img>
                        <v-toolbar-title class="d-flex px-3 mt-3"> Need Help?</v-toolbar-title>  
                    </div>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn depressed icon  @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                
                <v-card-text class="pa-5">
                    <div class="text-center pb-3 text-caption">
                        We're here to help you find the best room. For any general queries, please feel free to contact us using the details below.
                    </div>
                    <div class="contact-flex">
                    <div>
                        <v-icon large color="primarycolor">mdi-email</v-icon><br><a href="mailto:reservations@lionroars.com" target="_blank">reservations@lionroars.com</a>
                    </div>
                    <div>
                        <v-icon large color="primarycolor">mdi-phone</v-icon><br><a href="tel:+27214241530" target="_blank">+27 (0) 21 424 1530</a>
                    </div>
                    </div>
                   
                </v-card-text>

            </v-card> 
        </v-dialog>
    </div>
</template>

<script>

export default {

    props:{
      assistanceDialog:Boolean,
    },
    data: () => ({
        dialog: false,
    }),
    created() {
    this.initialize();
    },
    watch: {
        assistanceDialog: function (newVal, oldVal) {   
            if (!oldVal && newVal){
                this.dialog = true;
                this.$emit('reverseFlag', false);
            }
        },
    },
    methods: {
        initialize() {
            //init code goes here
           
        }
    },
}
</script>
