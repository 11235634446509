<template>
 <v-card class="p3">
        <v-toolbar
            flat
            dark
        >
            <div class="d-flex">
                <v-img :eager="true" contain height="50" width="50"  src="/icons/icon-152x152.png"></v-img>
                <v-toolbar-title class="d-flex px-3 mt-3">  Guest Signup</v-toolbar-title>  
            </div>
            
            <v-spacer></v-spacer>
            <v-toolbar-items>
            <v-btn depressed icon  @click="switchCancel()">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>

       
            <v-form
                ref="form"
                v-model="valid"
                class="pa-3"
            >
                <v-text-field
                    v-model="guest.firstName"
                    :rules="nameRules"
                    label="First name"
                    prepend-icon="mdi-account"
                    required
                    autocomplete='new'
                ></v-text-field>
                <v-text-field
                    v-model="guest.lastName"
                    :rules="nameRules"
                    label="Last name"
                    prepend-icon="mdi-account"
                    required
                    autocomplete='new'
                ></v-text-field>
            
                <vue-tel-input-vuetify 
                    v-model="guest.cellNumber"
                    :rules="cellNumberRules"
                    label="Mobile number"
                    placeholder=""
                    autocomplete="new"
                    :preferredCountries="['ZA','GB','US','FR','DE','IT']"
                    required
                    @input="inputPhone"
                ></vue-tel-input-vuetify>

               <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="guest.dateOfBirth"
                            label="Date of birth"
                            prepend-icon="mdi-calendar"
                            :rules="dobRules"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                    ref="picker"
                    v-model="guest.dateOfBirth"
                    no-title
                    :max="new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toISOString().substr(0, 10)"
                    min="1930-01-01"
                    @change="save"
                    ></v-date-picker>
                </v-menu>
           

                <v-text-field
                    v-model="guest.email"
                    :rules="emailRules"
                    prepend-icon="mdi-email"
                    label="Email"
                    autocomplete='new'
                    required
                ></v-text-field>

                <v-text-field
                    v-model="guest.password"
                    :rules="passwordRules"
                    prepend-icon="mdi-form-textbox-password"
                    label="Password"
                    autocomplete='new-password'
                    required
                     :type="value ? 'password' : 'text'"
                    :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="() => (value = !value)"
                ></v-text-field>

                <v-card-actions class="text-center">
                    <v-container fluid>
                        <v-row>
                            <v-col>
                            <v-btn
                                block
                                large
                                :disabled="!valid"
                                color="primarycolor"
                                class="mx-auto rounded-pill"
                                @click="signupCredentials()"
                            >
                                Signup
                            </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <span class="grey--text text-darken-1">
                                  Already registered? 
                                </span>
                                <span @click="switchLogin()" class='signup-btn'>Sign in</span>
                            </v-col>
                        </v-row>
                    </v-container>
                
                </v-card-actions>
            
            </v-form>
        </v-card-text>    
    </v-card>
</template>

<script>
export default {
    props:{
       swichedTo:Boolean,
    },
    data: () => ({
        value:String,
        guest:{
            firstName:'',
            lastName:'',
            cellNumber: '',
            dateOfBirth: null,
            email: '',
            password: '',           
        },
        menu: false,
        valid: false,
        name: '',
        nameRules: [
            v => !!v || 'Name is required',
        ],
        emailRules: [
             v => !!v || 'Email is required',
        ],
         cellNumberRules: [
             v => !!v || 'Call number is required',
        ],
         dobRules: [
             v => !!v || 'Date of birth is required',
        ],
        password: '',
        passwordRules: [
            v => !!v || 'Password is required',
            v => v.length >= 8 || 'Password must 8 or more characters',
        ],
    }),
    watch: {
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    },
    created() {
    this.initialize();
    },
    
    methods: {
        initialize() {
            //init code goes here
            
        },
        save (date) {
            this.$refs.menu.save(date)
        },
        signupCredentials(){
            let credentials = this.guest;
            this.$emit('signupCredentials', credentials)
        },
        switchLogin(){
            this.$emit("switch", 'login')
        },
        switchCancel(){
            this.$emit("switch", 'cancel')
        }, 
        inputPhone(formattedNumber, { number, valid, country }){
            this.guest.cellNumber = number.e164;
            // console.log("phone numbe object: ", number);
            
            },       
    },
    

}
</script>
