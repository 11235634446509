<template>
    <div>
        <v-main >
            <!-- Provides the application the proper gutter -->
            
            <v-app-bar dark fixed app>
                <div class="d-flex" @click="leaveCheckoutDialog = !leaveCheckoutDialog" style="cursor:pointer;">
                   <v-img :eager="true" contain height="50" width="50"  src="/icons/icon-152x152.png"></v-img>
                   <v-toolbar-title class="d-flex px-3 mt-3" >  Lion Roars Bookings</v-toolbar-title>
                </div>
                <v-spacer></v-spacer>
                <v-toolbar-title>Checkout</v-toolbar-title>  
                <v-menu
                left
                bottom
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        >
                        <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list dense class="profilelist">
                        <v-list-item>
                        <v-list-item-icon>
                            <v-icon >mdi-help-circle-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title @click="assistanceDialog = true">Need Assistance</v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                        <v-list-item style="cursor:default;">
                            <v-list-item-icon>
                                <v-icon >mdi-application</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title @click="privacyPolicyDialog = true">Privacy Policy</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                Payments are secured by
                                <a href="https://www.peachpayments.com/#/products/payments" target="_blank">
                                    <v-img src="peach_payment_logo_square.png" width="140" style="margin: auto;"></v-img>
                                </a>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-app-bar>   
            <v-container style="max-width:800px;"  >
                <v-card flat color="transparent" >
                    <v-card-text class="hide-scrollbar pa-0">
                        <v-row>
                            <v-col cols="12" class='pt-0' v-if="basket && basket.id">
                                <v-card class="booking-details elevated rounded-lg" flat>
                                    <v-card-title class="text-h5">
                                    Reservation
                                    </v-card-title>
                                    <v-card-subtitle>
                                        <strong>Status:</strong> Payment required
                                    </v-card-subtitle>
                                    <v-card-text>
                                        <div class="booking-subdetails justify-space-between">
                                            <div>
                                                <span><v-icon small color="primarycolor">mdi-calendar-range</v-icon> {{from}} - {{to}}</span>
                                            </div>
                                            <div>
                                                <span><v-icon color="primarycolor" small>mdi-bed</v-icon> {{numberOfNightsText}}</span>
                                            </div>
                                            <div v-if="basket.booker.company && basket.booker.company.length > 0">
                                                <span><v-icon small color="primarycolor">mdi-account-settings</v-icon> {{basket.booker.company}}</span>
                                            </div>
                                            <div>
                                                <span><v-icon small color="primarycolor">mdi-account-settings</v-icon> {{basket.booker.firstname}} {{basket.booker.lastname}}</span>
                                            </div>
                                           <div>
                                                <span><v-icon color="primarycolor" small>mdi-email</v-icon> {{basket.booker.email}}</span>
                                            </div>
                                        </div>
                                        <div class="booking-subdetails"  v-if="basket.notes">
                                            <div>
                                                <v-icon small color="primarycolor">mdi-note-outline</v-icon> {{basket.notes}}
                                            </div>
                                        </div>
                                    </v-card-text>
                                    <v-list subheader>
                                        <v-subheader class="text-h6" v-html="hotelName"></v-subheader>
                                        <div v-for="(item,index) in basket.basketItems" :key="index">
                                            <v-list-item>
                                                <v-list-item-content class="text-left">
                                                    <v-list-item-title v-text="item.categoryDesc"></v-list-item-title>
                                                    <v-list-item-subtitle v-text="displayGuestNames(item.guests)"></v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-list-item-title v-text="roomPriceFormat(item.amount)"></v-list-item-title>
                                                </v-list-item-action>
                                            </v-list-item>
                                            
                                        </div>
                                        <div v-if="basket.basketItems[0].bookableItems && basket.basketItems[0].bookableItems.length > 0">
                                            <v-subheader class="text-h6">Extras</v-subheader>
                                            <v-list-item v-for="(bookableItem, index1) in basket.basketItems[0].bookableItems" :key="index1">
                                                <v-list-item-content class="text-left">
                                                  <v-list-item-title v-text="bookableItem.description"></v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                  <v-list-item-title v-text="roomPriceFormat(bookableItem.amount)"></v-list-item-title>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </div>
                                        <v-list-item v-if="refundable"> 
                                            <v-list-item-content class="text-left">
                                                <v-list-item-title>Refundable Booking</v-list-item-title>
                                                <v-list-item-subtitle>Upgrade: This is a refundable booking.</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-list-item-title v-text="getRefundPremium()"></v-list-item-title>
                                            </v-list-item-action>
                                        </v-list-item>    
                                        <v-list-item>
                                            <v-list-item-content class="text-left">
                                                <v-list-item-title><strong>Amount Payable</strong></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-list-item-title ><strong>{{roomTotalPriceFormat(basket.basketItems)}}</strong></v-list-item-title>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-col>
                            <v-col cols="12">
                                <v-card flat class="elevated rounded-lg">
                                  <v-card-title class="">
                                    Select payment method
                                  </v-card-title>
                                  <v-radio-group class="mt-0"
                                                 v-model="paymentMethod"
                                  >
                                    <v-row>
                                      <v-col sm="6" xs="12">
                                        <v-card-text class="px-10" style="width:50%;">
                                          <v-radio
                                              value="creditCard"
                                              color="secondarycolor"
                                          >
                                            <template v-slot:label>
                                              <div style="height:100px;width:100%;">
                                                <v-img src="peach_payment_logo_square.png" width="170" style="margin: auto;"></v-img>
                                              </div>
                                            </template>
                                          </v-radio>
                                        </v-card-text>
                                      </v-col>
                                      <v-col  sm="6" xs="12" v-if="basket && basket.booker">
                                        <v-card-text class="px-10"  style="width:50%;">
                                          <v-radio
                                              value="payFlex"
                                              color="secondarycolor"
                                          >
                                            <template v-slot:label>
                                              <div style="height:100px;width:100%;">
                                                <v-img src="https://widgets.payflex.co.za/assets/Payflex_purple.png" width="170" style="margin: auto; top:24px;"></v-img>
                                                <small style="margin: auto; position: relative; top:24px; white-space: nowrap;">Extended payment terms possible<br>Exclusive to South African residents</small>
                                              </div>
                                            </template>
                                          </v-radio>
                                        </v-card-text>
                                      </v-col>
                                    </v-row>
                                  </v-radio-group>
                                </v-card>
                            </v-col>

                           <span ref="scrollTo"></span>

                            <v-col cols="12" :style="getPayFlexStyleObject" v-if="basket && basket.basketItems">
                              <v-card flat class="elevated rounded-lg" ref="payflexCard">
                                <v-card-title class="">
                                  Payflex Payment
                                </v-card-title>
                                <v-card-title class="">
                                  <script async :src="payflexScriptSource(basket.basketItems)" type="application/javascript"></script>
                                </v-card-title>
                                <v-card-actions >
                                  <v-btn  rounded color="success" @click="postPreparePayflexOrder()" class="mx-auto">Pay Now</v-btn>
                                </v-card-actions>
                                <v-card-actions >
                                  <v-btn text rounded color="warning" @click="leaveCheckoutDialog = !leaveCheckoutDialog" class="mx-auto">Cancel Checkout</v-btn>
                                </v-card-actions>
                                <v-card-text class="justify-center" style="text-align: center;">
                                  <p>Payments are secured by</p>
                                  <a href="https://payflex.co.za/#howitworks" target="_blank">
                                    <v-img src="https://widgets.payflex.co.za/assets/Payflex_purple.png"  width="246" style="margin: auto;"></v-img>
                                  </a>
                                </v-card-text>
                              </v-card>
                            </v-col>
                            <v-col cols="12" :style="getCreditCardStyleObject">
                                <v-card flat class="elevated rounded-lg">
                                    <v-card-title class="">
                                        Credit Card Payment
                                    </v-card-title>

                                    <v-card-text class="">
                                        <form :action="returnRoute" class="paymentWidgets" data-brands="VISA MASTER"></form>
                                    </v-card-text>
                                    <v-card-actions >
                                         <v-btn text rounded color="warning" @click="leaveCheckoutDialog = !leaveCheckoutDialog" class="mx-auto">Cancel Checkout</v-btn>
                                    </v-card-actions>
                                    <v-card-text class="justify-center" style="text-align: center;">
                                        <p>Payments are secured by</p>
                                        <a href="https://www.peachpayments.com/#/products/payments" target="_blank">
                                            <v-img src="peach_payment_logo_square.png"  width="246" style="margin: auto;"></v-img>
                                        </a>
                                    </v-card-text>

                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
              <div style="height:200px;"></div>

                <v-dialog
                    v-model="leaveCheckoutDialog"
                    persistent
                    max-width="290"
                    >
                    <v-card rounded >
                        <v-card-title class="headline">
                        Leaving checkout?
                        </v-card-title>
                        <v-card-text>Once you leave checkout your reservation details will be reset.<br>Are you sure you want to leave checkout?</v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="green darken-1"
                                text
                                @click="leaveCheckoutDialog = false"
                            >
                                No
                            </v-btn>
                            <v-btn
                                color="green darken-1"
                                text
                                @click="leaveCheckout"
                            >
                                Yes
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <assistance-dialog v-bind:assistanceDialog="assistanceDialog"  @reverseFlag="assistanceDialog = $event" />
                <privacy-policy-dialog v-bind:privacyPolicy="privacyPolicy" v-bind:privacyPolicyDialog="privacyPolicyDialog" @reversePrivacyPolicyFlag="privacyPolicyDialog = $event" />

            </v-container>
        </v-main>
          <!-- notification bar -->
        <v-snackbar v-model="snackbar.snackbar" :timeout="snackbar.timeout" top rounded="pill" elevation="24">
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar.snackbar = false">
                Close
            </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import axios from "axios";
import moment from 'moment';

export default {
    props:{
       basketId: String,
       checkoutId: String,
       transactionNumber: String
    },
    data: () => ({
        assistanceDialog:false,
        cardToken: '',
        returnRoute: window.Routes.return_from_gateway_reservations_path(),
        basket: Object,
        // bookableItems: Array,
        hotelName: String,
        from: String,
        to: String,
        snackbar:{
            snackbar:false,
            text:'',
            timeout: 3000
        },
        leaveCheckoutDialog: false,
        numberOfNightsText:String,
        privacyPolicy: String,
        privacyPolicyDialog: false,
        refundable: Boolean,
        refund_premium: Number,
        paymentMethod: String,
    }),
    created() {
    this.initialize();
    },
    computed: {
      getPayFlexStyleObject() {
        return {
          display: this.paymentMethod === 'payFlex' ? 'block' : 'none',
        };
      },
      getCreditCardStyleObject() {
        return {
          display: this.paymentMethod === 'creditCard' ? 'block' : 'none',
        };
      },
    },
    watch: {
      paymentMethod: function (newVal, oldVal) {
        if (newVal === 'payFlex' || newVal === 'creditCard') {
          setTimeout(() => {
            const element = this.$refs.scrollTo;
            element.scrollIntoView({behavior: 'smooth', block: "start", inline: "start"});
          }, 100);

        }
      },
    },
    methods: {
        initialize() {
            //init code goes here
           this.getBasketDetails();
           console.log(this.basket)
        },
        getBasketDetails(){
            return axios
            .get(window.Routes.get_basket_details_hotels_path(), {params: {format: 'json', basketId: this.basketId}})
            .then(response => {
                console.log(response.data)
                this.basket = response.data.basket;
                this.hotelName = this.basket.basketItems[0].mpename;
                this.from = this.displayCheckIn(this.basket.basketItems[0].from);
                this.to = this.displayCheckOut(this.basket.basketItems[0].to);
                this.numberOfNightsText = this.numberOfNightsShortText(this.basket.basketItems[0].from, this.basket.basketItems[0].to);
                this.privacyPolicy = response.data.privacyPolicy
                this.refundable = response.data.refundable
                this.refund_premium = response.data.refund_premium
            })
            .catch(error => {
                console.log(error);
            });
        },
        postPreparePayflexOrder(){
          const thisPrice = this.roomTotalPrice(this.basket.basketItems);
          return axios
          .post(window.Routes.post_prepare_payflex_order_payments_path(), {
            format: 'json',
            transaction_number: this.transactionNumber,
            price: thisPrice})
          .then(response => {
            console.log(response.data)
            if (response.data.success){
              // window.open(response.data.redirectUrl, '_self');
              location.href = response.data.redirectUrl;
            }else{
              console.log(response.data.message);
            }

          })
          .catch(error => {
            console.log(error);
          });
        },
        displayCheckIn($date){
            return moment($date).format('DD MMM YYYY')
        },
        displayCheckOut($date){
            return moment($date).format('DD MMM YYYY')
        },    
        numberOfNightsShortText(from, to){
            let nightCount = this.calcNights(from,to);
            if (nightCount > 1){
                return `${nightCount} Nights`
            }else{
                return `${nightCount} Night`
            }
        },
        calcNights(from,to){
            let _to = moment(to);
            let _from = moment(from);
            return _to.diff(_from,'days');
        },
        displayGuestNames(guests){
            let guestArray = [];
            guests.forEach(element => {
                guestArray.push(element.firstname)
            });
            if (guestArray.length > 1){
                return `Guests: ${guestArray.join(', ')}`;
            }else{
                return `Guest: ${guestArray.join(', ')}`;
            }
            
        }, 
        roomPriceFormat(amount){
            let price = amount.numeric / 100;
            let currency = amount.currency;
            return `${currency} ${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
        },
        getRefundPremium(){
            return `ZAR ${this.refund_premium.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
        },
        roomTotalPriceFormat(items){
            let currency = items[0].amount.currency;
            let price = 0;
            items.forEach(item => {
                price += item.amount.numeric / 100;
                if (item.bookableItems != undefined && item.bookableItems.length > 0){
                    item.bookableItems.forEach(bookableItem => {
                        price += bookableItem['amount']['numeric'] / 100
                    })
                }
            });
            if (this.refundable){
                price += this.refund_premium;
            }
            return `${currency} ${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
        },
        roomTotalPrice(items){
          let currency = items[0].amount.currency;
          let price = 0;
          items.forEach(item => {
            price += item.amount.numeric / 100;
            if (item.bookableItems != undefined && item.bookableItems.length > 0){
              item.bookableItems.forEach(bookableItem => {
                price += bookableItem['amount']['numeric'] / 100
              })
            }
          });
          if (this.refundable){
            price += this.refund_premium;
          }
          return price;
        },
        payflexScriptSource(items){
          // return `https://widgets.payflex.co.za/lion-roars-hotels/partpay-widget-0.1.2.js?type=calculator&amount=${this.roomTotalPrice(items)}`
          return `/javascripts/partpay-widget-0.1.2.js?type=calculator&amount=${this.roomTotalPrice(items)}`

        },
        leaveCheckout(){
            window.open(window.Routes.reservations_path(), '_self');    
        },   
    },
    
}
</script>
