<template>
    <v-main >
        <v-container style="max-width:800px;">
            <v-app-bar dark fixed  app>      
                <v-spacer></v-spacer>
                <v-toolbar-title>Checking Payment</v-toolbar-title>  
            </v-app-bar>
            <v-dialog
                v-model="dialogLoading"
                hide-overlay
                persistent
                width="300"
                >
                <v-card
                    color="primarycolor"
                    dark
                >
                    <v-card-text>
                        <div class="py-1">{{modalTitle}}</div>
                        <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-sheet
                :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
                class=""
                >
                <v-skeleton-loader
                    class="mx-auto"
                    type=" table-heading, list-item-three-line,list-item-three-line,list-item-three-line, table-tfoot"
                ></v-skeleton-loader>
            </v-sheet>
        </v-container>
    </v-main>
</template>

<script>
import axios from "axios";
export default {
    inject: {
      theme: {
        default: { isDark: false },
      },
    },
    props:{
       gatewayResponse: Object,
       status: String,
    },
    data: () => ({
        dialogLoading: true,
        modalTitle: String,
        token: String,
        orderId: String
    }),
    created() {
    this.initialize();
    },
    methods: {
        initialize() {
            //init code goes here
            console.log("GATEWAY RESPONSE:", this.gatewayResponse);
            this.token = this.gatewayResponse.token
            this.orderId = this.gatewayResponse.orderId
            this.updateTransactionStatus()
            
            this.modalTitle = 'Fetching payment information...'
        },
        updateTransactionStatus(){
            console.log("STATUS: ", this.status);
            axios
            .post(window.Routes.post_payflex_transaction_status_payments_path(), {
                format: 'json',
                token: this.token,
                order_id: this.orderId,
                status: this.status
            })
            .then(response => {
                if (response.data.success){
                    //if successful - go fetch reservation details and update basket status
                    this.modalTitle = 'Fetching reservation information...'
                    return axios
                    .post(window.Routes.post_reservation_details_payments_path(), {
                    format: 'json',
                    basketId: response.data.basketId
                    })
                    .then(response2 => {
                        if (response.data.success){
                            window.open(window.Routes.checkout_complete_reservations_path() + '?orderId=' + this.orderId + '&transactionNumber=' + response.data.transactionNumber, '_self');
                        }else{
                            window.open(window.Routes.checkout_complete_reservations_path() + '?orderId=' + this.orderId + '&transactionNumber=' + response.data.transactionNumber + '&errorMessage=' + response2.data.errorMessage.message , '_self');
                        }
                    })
                    .catch(error2 => {
                        window.open(window.Routes.checkout_complete_reservations_path() + "?orderId=" + this.orderId + "&errorMessage=Something went wrong. Couldn't retrieve reservation details.", '_self');
                    });
                }else{
                    window.open(window.Routes.retry_checkout_reservations_path() + '?orderId=' + this.orderId + '&transactionNumber=' + response.data.transactionNumber + '&errorMessage=' + response.data.errorMessage.message , '_self');
                }
            })
            .catch(error => {
               console.log("Error: ", error);
               window.open(window.Routes.retry_checkout_reservations_path() + "?orderId=" + this.orderId  + "&errorMessage=Something went wrong. Couldn't retrieve transaction status.", '_self');
            });
        },
        

    },
    

}
</script>
