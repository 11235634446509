<template>
    <div>
        <v-navigation-drawer v-model="drawer" fixed top temporary >
            <v-list nav dense color="" >
                <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4" >
                <v-list-item>
                    <v-list-item-title>Foo</v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>Bar</v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>Fizz</v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>Buzz</v-list-item-title>
                </v-list-item>
                </v-list-item-group>
            </v-list>
            </v-navigation-drawer>

            <v-app-bar color="deep-purple accent-4" dense dark fixed>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title>Page title</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon>
                <v-icon>mdi-heart</v-icon>
            </v-btn>

            <v-btn icon>
                <v-icon>mdi-magnify</v-icon>
            </v-btn>

            <v-menu left bottom >
                <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" >
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
                </template>

                <v-list>
                <v-list-item v-for="n in 5" :key="n" @click="() => {}" >
                    <v-list-item-title>Option {{ n }}</v-list-item-title>
                </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
    </div>
</template>

<script>

export default {
  data: () => ({
      //public and private variables go here
      drawer: false,
      group: null,
    
  }),
  watch: {
    group () {
      this.drawer = false
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      //init code goes here.
    }
  }
}
</script>
