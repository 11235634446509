<template>
 <v-card class="p3">
        <v-toolbar
            flat
            dark
        >
            <div class="d-flex">
                <v-img :eager="true" contain height="50" width="50"  src="/icons/icon-152x152.png"></v-img>
                <v-toolbar-title class="d-flex px-3 mt-3">  Forgot Password</v-toolbar-title>  
            </div>
            <v-spacer></v-spacer>
            <v-toolbar-items>
            <v-btn depressed icon  @click="switchCancel()">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-form
                ref="form"
                v-model="valid"
                class="pa-3"
            >
            
                <v-text-field
                    v-model="email"
                    :rules="nameRules"
                    prepend-icon="mdi-email"
                    label="Email"
                    required
                    autocomplete='new'
                ></v-text-field>

                <v-card-actions class="text-center">
                    <v-container fluid>
                        <v-row>
                            <v-col>
                            <v-btn
                                block
                                large
                                :disabled="!valid"
                                color="success"
                                class="mx-auto"
                                @click="forgotPasswordEmailSubmit()"
                            >
                                Submit
                            </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                 <span class="grey--text text-darken-1">
                                  Already registered? 
                                </span>
                                <span @click="switchLogin()" class='signup-btn'>Sign in</span>
                            </v-col>
                            <!-- <v-col>
                                <v-btn
                                    class="mx-auto"
                                    @click="switchCancel()"
                                    text
                                    x-small
                                >
                                    Cancel
                                </v-btn>
                            </v-col> -->
                        </v-row>
                    </v-container>
                </v-card-actions>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props:{
       swichedTo:Boolean,
    },
    data: () => ({
        
        email: '', 
        valid: false,
        name: '',
        nameRules: [
            v => !!v || 'Name is required',
        ],
    }),
    created() {
    this.initialize();
    },
    methods: {
        initialize() {
            //init code goes here
            
        },
        forgotPasswordEmailSubmit(){
            this.$emit('forgotPasswordEmail', this.email)
        },
        switchLogin(){
            this.$emit("switch", 'login')
        },
        switchCancel(){
            this.$emit("switch", 'cancel')
        },        
    },
    

}
</script>
