
<template>
  <div v-if="selectedRoomTypes"> 
    <v-app-bar dark fixed app>
        <v-btn icon @click="gotoArticlesForm">
            <v-icon>mdi-keyboard-backspace</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>Guest details</v-toolbar-title>  
        <v-menu
          left
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="menuClick()"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-card color="primarycolor" dark depressed elevation="0" v-if="loggedInGuestId && loggedInGuestId.length" class="profilepanel rounded-0">
            <v-card-title class="text-subtitle-2 white--text">{{loggedInGuestName}}</v-card-title>
            <v-card-subtitle class="text-caption">Rands Earned:<br>ZAR {{loggedInGuestPoints}}.00</v-card-subtitle>
          </v-card>
          <v-list dense class="profilelist">
            <v-list-item>
              <v-list-item-icon>
                <v-icon >mdi-help-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="assistanceDialog = true">Need Assistance</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item style="cursor:default;">
              <v-list-item-icon>
                <v-icon >mdi-application</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="$emit('showPrivacyPolicy', true);">Privacy Policy</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item style="cursor:default;">
              <v-list-item-icon>
                <v-icon >mdi-application</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="$emit('showTermsAndConditions', true);">Terms and Conditions</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Payments are secured by
                  <a href="https://www.peachpayments.com/#/products/payments" target="_blank">
                     <v-img src="peach_payment_logo_square.png" width="140" style="margin: auto;"></v-img>
                  </a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
    </v-app-bar>
    
    <v-card flat color="transparent" >
      <v-card-text class="hide-scrollbar pa-0">
    <v-card flat class="booking-details">
          <v-card-title v-html="searchParams.hotelName"></v-card-title>
          <v-card-subtitle>
            <v-icon small color="primarycolor">mdi-calendar-range</v-icon> {{displayCheckIn(searchParams.from)}} - {{displayCheckOut(searchParams.to)}}
          </v-card-subtitle>
          <v-card-text>
            <div class="booking-subdetails d-flex justify-space-between">
              <div>
                <span><v-icon color="primarycolor" small>mdi-human-male</v-icon> {{adultCountText()}}</span><span v-if="getChildrenCount() > 0" class="ml-2"><v-icon color="primarycolor" small>mdi-human-male-boy</v-icon> {{childrenCountText()}}</span>
              </div>
              <div>
                <!--TODO if only 1 night = text must read night, not nights-->
                <span><v-icon color="primarycolor" small>mdi-bed</v-icon> {{numberOfNightsShortText()}}</span>
              </div>
            </div>
          
          </v-card-text>
        </v-card>

        <v-container fluid class='pb-0'>
          <v-row>
            <v-col cols="12" class='info-box'>
              <v-card class="elevated rounded-md">
                <v-card-title>
                  Booker Details
                </v-card-title>
                <v-card-text>
                  <v-form v-model="formValid" >

                      <v-radio-group
                        v-model="booker.isCompany"
                        mandatory
                        row
                      >
                        <v-radio
                          label="Individual"
                          value="no"
                        ></v-radio>
                        <v-radio
                          label="Company"
                          value="yes"
                        ></v-radio>
                      </v-radio-group>

                      <v-text-field
                        v-if="booker.isCompany == 'yes'"
                        v-model="booker.companyName"
                        :rules="booker.companyNameRules"
                        :counter="40"
                        label="Company Name"
                        autocomplete="new"
                      ></v-text-field>

                       <v-text-field
                        v-if="booker.isCompany == 'yes'"
                        v-model="booker.vatNumber"
                        :counter="40"
                        label="VAT Number (optional)"
                        autocomplete="new"
                      ></v-text-field>
                    
                      <v-text-field
                        v-model="booker.firstname"
                        :rules="booker.firstnameRules"
                        :counter="30"
                        label="Name"
                        autocomplete="new"
                        required
                      
                        :disabled="loggedInGuest.firstName != undefined && loggedInGuest.firstName != '' "
                      ></v-text-field>
                  
                      <v-text-field
                        v-model="booker.lastname"
                        :rules="booker.lastnameRules"
                        :counter="30"
                        label="Surname"
                        autocomplete="new"
                        required
                  
                        :disabled="loggedInGuest.lastName != undefined && loggedInGuest.lastName != '' "
                      ></v-text-field>
                  
                      <v-text-field
                        v-model="booker.email"
                        :rules="booker.emailRules"
                        label="E-mail"
                        autocomplete="new"
                        required
                      
                        :disabled="loggedInGuest.email != undefined && loggedInGuest.email != '' "
                      ></v-text-field>
                    
                      <vue-tel-input-vuetify 
                        v-model="booker.cellNumber"
                        :rules="booker.cellNumberRules"
                        label="Mobile number"
                        placeholder=""
                        autocomplete="new"
                        :preferredCountries="['ZA','GB','US','FR','DE','IT']"
                        required
                        @input="inputPhone"
                      ></vue-tel-input-vuetify>
                    
                  </v-form>  
                </v-card-text>
              </v-card>

              
              <v-card class="mt-3 elevated rounded-md">
                <v-card-title>
                  Guest Details
                </v-card-title>

                <template v-for="(room,index) in selectedRoomTypes" >
                  <v-card :key="index" flat>
                    <v-card-text>
                      <div class="d-flex">
                        <!--<div class="guest-nr">-->
                        <!--  {{index + 1}}-->
                        <!--</div>-->
                        <div class="guest-room">
                          {{room.name}}
                        </div>
                      </div>
                    
                      <template  v-for="(guest,index2) in room.guests" >
                        <div class="px-4" :key="`${index}_${index2}_${room.id}`">
                          Guest {{index2 + 1}} 
                          <v-btn v-if="!sameAsBooker"
                            dark
                            small
                            rounded
                            color="primarycolor"
                            class='mx-4 float-right'
                            @click="setGuestWithBookerDetails(guest)"
                          >
                          Same as booker
                          </v-btn>
                          
                          <div style="display:flex; margin-top: 10px;">
                            
                            <v-text-field
                        
                              v-model="guest.firstname"
                              :rules="booker.firstnameRules"
                              :counter="30"
                              label="First Name"
                              autocomplete="new"
                              required
                            ></v-text-field>
                            &nbsp;&nbsp;&nbsp;
                            <v-text-field
                            
                              v-model="guest.lastname"
                              :rules="booker.lastnameRules"
                              :counter="30"
                              label="Last Name"
                              autocomplete="new"
                              required
                            ></v-text-field>
                          </div>
                        </div>
                        
                      </template>
                    </v-card-text>
                  </v-card>
                  
                </template>
              </v-card>
              
                      
              <v-card class="mt-3 elevated rounded-md">
                <v-card-title>
                  Reservation Notes
                </v-card-title>
                <v-card-text class="pt-4">
                  <div class="px-4">
                    <v-textarea
                      dense
                      v-model="booker.notes"
                      hint="Is there something we should know about this reservation? Please tell us (optional)."
                      label="Leave a note"
                      autocomplete="new"
                      rows="3"
                    ></v-textarea>
                  </div>
                </v-card-text>
              </v-card>
              
            </v-col>
          </v-row>
        </v-container>
        
        <v-container fluid class='pt-0'>
          <v-row> 
            <v-col cols="12" class='pt-0 info-box'>
              <v-card class="booking-details elevated rounded-md" >
                <v-card-title class="">
                  Your Trip
                </v-card-title>
                
                  <v-card-text>
                    <div class="booking-subdetails d-flex justify-space-between">
                      <div>
                        <v-icon small color="primarycolor">mdi-calendar-range</v-icon> {{displayCheckIn(searchParams.from)}} - {{displayCheckOut(searchParams.to)}}
                      </div>
                      <div>
                        <span><v-icon color="primarycolor" small>mdi-bed</v-icon> {{numberOfNightsShortText()}}</span>
                      </div>
                    </div>
                    <div class="booking-subdetails"  v-if="booker.notes">
                      <div>
                        <v-icon small color="primarycolor">mdi-note-outline</v-icon> {{booker.notes}}
                      </div>
                    </div>
                  </v-card-text>



                <v-list subheader>
                  <v-subheader class="text-h6" v-html="searchParams.hotelName"></v-subheader>
                  <div v-for="(roomType,index) in selectedRoomTypes" :key="index" >
                    <v-list-item>
                      <v-list-item-content class="text-left"> 
                        <v-list-item-title v-text="roomType.name"></v-list-item-title>
                        <v-list-item-subtitle><v-icon color="primarycolor" small>mdi-qrcode</v-icon> <small>{{getPromoCode(roomType)}}</small></v-list-item-subtitle>
                        <v-list-item-subtitle v-text="displayGuestNames(roomType.guests, true)"></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-title v-text="roomPriceFormat(roomType)"></v-list-item-title> 
                        <!--<v-btn v-if="articles.length > 0" @click="showArticleManageDialog(roomType)" dark small rounded color="primarycolor" class='mx-4 float-right'>-->
                        <!--    Manage Extras-->
                        <!--</v-btn>-->
                      </v-list-item-action>
                    </v-list-item>

                    
                  </div>
                  <v-subheader v-if="articles.length > 0" class="text-h6" >Extras</v-subheader>
                    <!--<v-card-text v-if="!allArticlesAssigned(articles)">-->
                    <!--  <div class="booking-subdetails d-flex justify-space-between">-->
                    <!--    Please assign all extras by clicking "Manage Extras"-->
                    <!--  </div>-->
                    <!--</v-card-text>-->
                    <div v-if="anyArticlesAssigned(articles)">
                      <!--<v-subheader >Assigned</v-subheader>-->
                      <v-list-item v-for="(article, index1) in articles" :key="index1">
                        <v-list-item-content class="text-left">
                          <v-list-item-title v-text="article.name"></v-list-item-title>
                          <!--<v-list-item-subtitle><v-icon color="primarycolor" small>mdi-cart-arrow-down</v-icon> <small>Quantity: 1</small></v-list-item-subtitle>-->
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-title v-text="articlePriceFormat(article.currency, article.amount)"></v-list-item-title>
                        </v-list-item-action>
                      </v-list-item>
                    </div>
                  <!--<div v-if="!allArticlesAssigned(articles)">-->
                  <!--  <v-subheader >Un-assigned</v-subheader>-->
                  <!--  <v-list-item  v-for="(article,index) in articles" :key="index" v-if="article.roomId == null">-->
                  <!--    <v-list-item-content class="text-left">-->
                  <!--      <v-list-item-title v-text="article.name"></v-list-item-title>-->
                        <!--<v-list-item-subtitle><v-icon color="primarycolor" small>mdi-cart-arrow-down</v-icon> <small>Quantity: 1</small></v-list-item-subtitle>-->
                  <!--    </v-list-item-content>-->
                  <!--    <v-list-item-action>-->
                  <!--      <v-list-item-title v-text="articlePriceFormat(article.currency, article.amount)"></v-list-item-title>-->
                  <!--    </v-list-item-action>-->
                  <!--  </v-list-item>-->
                  <!--</div>-->

                  <v-list-item>
                    <v-list-item-content class="text-left"> 
                      <v-list-item-title><strong>Total Payable</strong></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-title v-html="roomTotalPriceFormat_v2(selectedRoomTypes, articles)"></v-list-item-title> 
                    </v-list-item-action> 
                  </v-list-item>

                  <v-list-item v-if="applyLoyaltyPoints(totalAccommodationCost(selectedRoomTypes))">
                      <v-list-item-title class="text-wrap"> <v-icon color="success">mdi-alert-circle-check-outline</v-icon> <em>You qualify to redeem your earned Rands</em></v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="applyLoyaltyPoints(totalAccommodationCost(selectedRoomTypes))">
                    <v-list-item-content class="text-left"> 
                      <v-checkbox
                        label="I want to redeem my earned Rands!"
                        @click="useLoyaltyPoints = !useLoyaltyPoints"
                      ></v-checkbox>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-title><strong>- ZAR {{qualifyingPoints(totalAccommodationCost(selectedRoomTypes))}}.00</strong></v-list-item-title> 
                    </v-list-item-action> 
                  </v-list-item>

                  <v-list-item v-if="applyLoyaltyPoints(totalAccommodationCost(selectedRoomTypes)) && useLoyaltyPoints ">
                    <v-list-item-content class="text-left"> 
                      <v-list-item-title><strong>Total Payable</strong></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-title v-html="roomTotalPriceFormat(selectedRoomTypes)"></v-list-item-title> 
                    </v-list-item-action> 
                  </v-list-item>

                </v-list>
              </v-card>

              <v-card class="mt-2 booking-details elevated rounded-md" v-if="loggedInGuestIsAgent && isPayable()">
                <v-card-title class="">
                  When do you want to pay?
                </v-card-title>

                <v-radio-group class="mt-0"
                               v-model="payLater"
                               mandatory
                >
                  <v-card-text class="pt-0">
                    <div class="booking-subdetails d-flex justify-space-between">
                      <div>
                        <v-radio
                            value="yes"
                            color="secondarycolor"
                        >
                          <template v-slot:label>
                            <div>Pay Later <v-icon class="mx-xs-1 mx-md-3" color="secondarycolor">mdi-clock-outline</v-icon> </div>
                          </template>
                        </v-radio>
                      </div>
                    </div>
                    <div class="refund-list">
                      <div class="px-4 pb-4">
                        Your reservation will be provisionally booked until payment is received in full. Please contact Lion Roars Reservations to settle the outstanding payment.<br>
                        Please note, the "Refundable Booking" option will not apply to reservations that pay later.
                      </div>
                    </div>
                  </v-card-text>
                  <v-card-text class="pt-0">
                    <div class="booking-subdetails d-flex justify-space-between">
                      <div>
                        <v-radio
                            value="no"
                            color="secondarycolor"
                        >
                          <template v-slot:label>
                            <div>Pay Now <v-icon class="mx-xs-1 mx-md-3" color="secondarycolor">mdi-credit-card-outline</v-icon> </div>
                          </template>
                        </v-radio>
                      </div>
                    </div>
                  </v-card-text>

                </v-radio-group>

              </v-card>

              <v-card class="mt-2 booking-details elevated rounded-md" v-if="!useLoyaltyPoints && willPayNow() && !specialPromos(selectedRoomTypes[0])">

                <v-card-title class="">
                  Refundable Booking
                </v-card-title>
                
                  <v-radio-group class="mt-0"
                    v-model="refundable"
                    mandatory
                  >
                    
                    <v-card-text class="pt-0">
                      <div class="recommended-mobile d-block d-md-none">Recommended</div>
                      <div class="booking-subdetails d-flex justify-space-between">
                        
                        <div>
                          <v-radio
                            value="yes"
                            color="secondarycolor"
                          >
                          <template v-slot:label>
                            <div>Refundable Booking <v-icon class="mx-xs-1 mx-md-3" color="secondarycolor">mdi-shield-check-outline</v-icon> <v-chip class="d-none d-md-inline-flex" text-color="white" :ripple="false" small color="secondarycolor">RECOMMENDED</v-chip></div>
                          </template>
                          </v-radio>
                        </div>
                        
                        <div>
                          <v-list-item-title v-html="refundableBookingAmountCalc(selectedRoomTypes)"></v-list-item-title>
                        </div>
                        
                      </div>
                      <div class="refund-list">
                        <v-list dense>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="secondarycolor">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Sickness, Accident, Injury</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="secondarycolor">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Home emergency</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="secondarycolor">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Public transport failure</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="secondarycolor">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Private vehicle failure</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="secondarycolor">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Pre-existing medical conditions</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="secondarycolor">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Emergency services recall</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="secondarycolor">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>And many more...</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        <div class="px-4 pb-4">
                        Upgrade your booking and receive a <strong>100% refund</strong> if you cannot attend and can evidence one of the many reasons in our <a href="https://www.refundable.me/extended/en/" target="_blank" class="font-weight-medium primarycolor--text text-decoration-none">Terms and Conditions</a>, which you accept when you select a Refundable Booking
                      </div>
                      </div>
                      
                    </v-card-text>

                    <v-card-text class="pt-2">
                      <div class="booking-subdetails d-flex justify-space-between non-refundable">
                        <div>
                          <v-radio
                            value="no"
                            color="secondarycolor"
                          >
                            <template v-slot:label>
                            <div>Non-Refundable Booking <v-icon class="mx-xs-1 mx-md-3" color="grey">mdi-shield-off-outline</v-icon></div>
                          </template>
                          </v-radio>
                        </div>
                        <div>
                          <span>&nbsp;</span>
                        </div>
                      </div>
                    </v-card-text>

                </v-radio-group>
                
              </v-card>

              <template  v-if="allGuestsFilledIn(selectedRoomTypes) && formValid && allArticlesAssigned(articles)">
                <v-card class="mt-2 booking-details elevated rounded-md">
                  <v-card-title>
                    Terms and conditions
                  </v-card-title>
                  <v-card-text>
                    <p>By checking this box you agree to the <span style="color:primarycolor; cursor:pointer;" @click="termsAndConditionsDialog = true"><u>terms and conditions</u></span> of this booking.</p>
                    <v-checkbox
                      v-model="agreeToTerms"
                      label="Agree to terms and conditions"
                    ></v-checkbox>
                    
                  </v-card-text>
                </v-card>
                <p class="text-center mt-1" style="color: #a8a8a8;"><small><strong>Please note:</strong><br> You cannot change your trip details once you have finalised!</small></p>
                <v-btn class='mt-4'
                  block
                  dark
                  color="primarycolor"
                  rounded
                  large
                  @click="proceedToCheckout"
                  :loading="loading == isSearching" 
                >
                {{isFree() ? 'Finalise Reservation' : 'Finalise & Pay'}} 
                </v-btn> 
              </template >
    
              <v-btn v-else
              block
              dark
              large
              rounded
              color="grey"
              class='mt-4'
              >
                Complete form above
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

      </v-card-text>
    </v-card>

   
    <v-dialog
        v-model="termsAndConditionsDialog"
        scrollable
        max-width="599px"
    >
        <v-card rounded class="pa-3">
            <v-card-text v-html="termsAndConditions"></v-card-text>
            <v-card-actions  class="pa-3">

                <v-btn
                    color="green darken-1"
                    text
                    @click="termsAndConditionsDialog = false"
                >
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog> 

    <v-dialog
        v-model="manageArticlesDialog"
        scrollable
        max-width="599px"
    >
        <v-card rounded class="pa-3">
            <v-card-text>
              <div class="booking-subdetails d-flex">
                <div>
                  <span class="text-h6">{{currentSelectedItem.name}}</span>
                </div>
                
              </div>

              <div v-if="articlesAssignedToRoom(articles, currentSelectedItem.id)">
                <v-subheader class="">Assigned Extras</v-subheader>
                <v-list-item  v-for="(article,index) in articles" :key="index" v-if="article.roomId == currentSelectedItem.id" >
                  <v-list-item-content class="text-left">
                    <v-list-item-title v-text="article.name"></v-list-item-title>
                    <!--<v-list-item-subtitle><v-icon color="primarycolor" small>mdi-cart-arrow-down</v-icon> <small>Quantity: 1</small></v-list-item-subtitle>-->
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn @click="removeArticleFromBasketItem(currentSelectedItem.id, article.articleId)" dark small rounded color="primarycolor" class='mx-4 float-right'>
                      Remove
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </div>

              <div v-if="!allArticlesAssigned(articles)">
                <v-subheader class="">Un-assigned Extras</v-subheader>
                <v-list-item  v-for="(article,index) in articles" :key="index" v-if="article.roomId == null" >
                  <v-list-item-content class="text-left">
                    <v-list-item-title v-text="article.name"></v-list-item-title>
                    <!--<v-list-item-subtitle><v-icon color="primarycolor" small>mdi-cart-arrow-down</v-icon> <small>Quantity: 1</small></v-list-item-subtitle>-->
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn @click="addArticleFromBasketItem(currentSelectedItem.id, article.articleId)" dark small rounded color="primarycolor" class='mx-4 float-right'>
                      Add
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </v-card-text>
            
            <v-card-actions  class="pa-3">

                <v-btn
                    color="green darken-1"
                    text
                    @click="manageArticlesDialog = false"
                   
                >
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog> 

    <v-dialog
      v-model="isSearching"
      
      persistent
      width="300"
      >
      <v-card
          color="primarycolor"
          dark
      >
          <v-card-text>
            <div class="py-1"> Finalising basket for checkout...</div>
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
          </v-card-text>
      </v-card>
    </v-dialog>
    <assistance-dialog v-bind:assistanceDialog="assistanceDialog"  @reverseFlag="assistanceDialog = $event" />
    <!-- notification bar -->
    <v-snackbar v-model="snackbar.snackbar" :timeout="snackbar.timeout" top rounded="pill" elevation="24">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar.snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

import moment from 'moment';


export default {
  //  components: {
  //   VueTelInputVuetify,
  // },
  props: {
    resultSet: Object,
    isSearching: Boolean,
    termsAndConditions:String,
    loggedInGuest: Object,
  },
  data: () => ({
    assistanceDialog:false,
    selectedRoomTypes: Array,
    searchParams: Object,
    selectedArticles: Array,
    articles: Array,
    phoneObject: Object,
    sameAsBooker: false,
    formValid: false,
    loading: true,
    guestCount: 1,
    availablePoints: Number,
    booker:{
      isCompany: 'no',
      isCompanyRules:[
        v => !!v || 'required',
      ],
      companyName: '',
      companyNameRules:[
        v => !!v || 'required',
        v => v.length <= 40 || 'less than 40 characters',
      ],
      vatNumber: '',
      firstname: '',
      firstnameRules: [
        v => !!v || 'required',
        v => v.length <= 30 || 'less than 30 characters',
      ],
      lastname: '',
      lastnameRules: [
        v => !!v || 'required',
        v => v.length <= 30 || 'less than 30 characters',
      ],
      email: '',
      emailRules: [
        v => !!v || 'required',
        v => /.+@.+/.test(v) || 'invalid email',
      ],
      cellNumber: '',
      cellNumberRules:[
        v => !!v || 'required',
        v => /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{2,3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(v) || 'invalid phone number',
      ],
      ageRules:[
         v => !!v || 'required'
      ],
      notes: ''
    },
    termsAndConditionsDialog:false,
    manageArticlesDialog:false,
    currentSelectedItem:Object,
    agreeToTerms:false,
    pointsBalance: Number,
    snackbar:{
      snackbar:false,
      text:'',
      timeout: 3000
    },
    refundableBookingAmount: Number,
    refundable: null,
    // logggedInUser: false,

    //This option is used for agents
    payLater: "no",

    useLoyaltyPoints: false,

    loggedInGuestId:String,
    loggedInGuestName:String,
    loggedInGuestPoints:String,
    loggedInGuestPromocodes:String,
    loggedInGuestIsAgent: false,
  }),
  mounted() {
    window.onpopstate = event => {
       this.$emit('gotoArticlesForm')
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      //init method code goes here
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
      });
      this.selectedRoomTypes = this.resultSet.selectedRoomTypes;
      this.searchParams = this.resultSet.searchParams;
      this.selectedArticles = this.resultSet.articles;
      console.log("selected articles array", this.selectedArticles)
      if (this.selectedArticles == undefined){
        console.log("it is undefined")
        this.articles = []
        console.log("what is articles .length?: ", this.articles.length)
      } else {
        this.articles = this.processSelectedArticles(this.selectedArticles);
      }
      if (this.loggedInGuest.firstName != undefined){
        this.booker.firstname = this.loggedInGuest.firstName;
        this.booker.lastname = this.loggedInGuest.lastName;
        this.booker.cellNumber = this.loggedInGuest.cellNumber;
        this.booker.email = this.loggedInGuest.email;
        this.pointBalance = this.loggedInGuest.pointBalance;

        this.loggedInGuestIsAgent = (localStorage.getItem('guestIsAgent') && localStorage.getItem('guestIsAgent').length > 0 && localStorage.getItem('guestIsAgent') === 'yes') ? true : false;

        console.log("IS AGENT: ", this.loggedInGuestIsAgent)
        // ******** no longer required to show to specific users ****************** 
        // if (this.loggedInGuest.email.includes("lionroars.com") || this.loggedInGuest.email.includes("perfectcircle.co.za") || this.loggedInGuest.email.includes("aymondgoncalves1506") ){
        //   this.logggedInUser = true;
        // }else{
        //   this.logggedInUser = false;
        // }
      }
    
      this.availablePoints = (localStorage.getItem('pointBalance') && localStorage.getItem('pointBalance').length > 0) ? localStorage.getItem('pointBalance') : 0;
    },
    menuClick(){
      this.loggedInGuestId = (localStorage.getItem('guestId') && localStorage.getItem('guestId').length > 0) ? localStorage.getItem('guestId') : null;
      this.loggedInGuestName = (localStorage.getItem('guestName') && localStorage.getItem('guestName').length > 0) ? localStorage.getItem('guestName') : null;
      this.loggedInGuestPoints = (localStorage.getItem('pointBalance') && localStorage.getItem('pointBalance').length > 0) ? localStorage.getItem('pointBalance') : null;
      this.loggedInGuestPromocodes = (localStorage.getItem('guestPromocodes') && localStorage.getItem('guestPromocodes').length > 0) ? localStorage.getItem('guestPromocodes') : null;
    },
    setGuestWithBookerDetails(guest){
      guest.firstname = this.booker.firstname;
      guest.lastname = this.booker.lastname;
      if (guest.firstname.length > 0 && guest.lastname.length > 0){
        this.sameAsBooker = !this.sameAsBooker;
      }
    },
    specialPromos(roomType){
      return (roomType.rate.promocode?.length > 0 && roomType.rate.promocode === 'BLACKFRIDAY23');
    },
    getPromoCode(roomType){
      let descript = '';
      if (roomType.rate.name == 'BESTAV'){
        descript = 'Best available rate has been applied';
      }else if(roomType.rate.name == 'BOOKDIRECT10'){
        descript = "Extra discount applied - guest is registered on Lion Roars Bookings"
      }else if(roomType.rate.name == 'PAYSTAY'){
        descript = "Extra discount applied - booking is 3 nights or more"
      }else if(roomType.rate.name == 'LM3'){
        descript = "Extra discount applied - booking is 3 days or less prior to stay";
      }else if(roomType.rate.name == 'LM7'){
        descript = "Extra discount applied - booking is 4 to 7 days prior to stay";
      }else if(roomType.rate.name == 'LM14'){
        descript = "Extra discount applied - booking is 8 to 14 days prior to stay";
      }else if(roomType.rate.name == 'LM30'){
        descript = "Extra discount applied - booking is 15 to 30 days prior to stay";
      }else if(roomType.rate.promocode?.length > 0){
        descript = `Promotion applied - ${roomType.rate.promocode}`;
      }else{
        descript = `${roomType.rate.rateDescription} rack rate has been applied`;
      }
      return descript
    },
    inputPhone(formattedNumber, { number, valid, country }){
      let $number = number.e164;
      if ($number){
        this.booker.cellNumber = number.e164;
      }else{
        this.booker.cellNumber = '';
      }
     
      // console.log("phone numbe object: ", number);
     
    },
     adultCountText(){
      let adultCount = this.searchParams.adultCount;
      if (adultCount > 1){
        return `${adultCount} Adults`;
      }else{
        return `${adultCount} Adult`;
      }
    },
    childrenCountText(){
      let childCount = this.getChildrenCount();
      if (childCount > 1){
        return `${childCount} Children`;
      }else{
        return `${childCount} Child`;
      }
    },
    firstNameLabel(guestNumber){
      return `Guest ${guestNumber} first name`
    },
    lastNameLabel(guestNumber){
      return `Guest ${guestNumber} last name`
    },
    getChildrenCount(){
      return this.searchParams.children1Count + this.searchParams.children2Count + this.searchParams.children3Count + this.searchParams.children4Count
    },
    numberOfNightsShortText(){
      let nightCount = this.selectedRoomTypes[0].rate.days.length;
      if (nightCount > 1){
        return `${nightCount} Nights`
      }else{
        return `${nightCount} Night`
      }
    },
    allGuestsFilledIn(rooms){
      let allFilled = true;
      rooms.forEach(room => {
        room.guests.forEach(element => {
          if (element.firstname.length == 0 || element.lastname.length == 0 || (element.age && element.age.length == 0)){
            allFilled = false
          }
        });
      })
      if (this.refundable == null){
        this.refundable = 'no'
      }
      return allFilled;
    },
    allArticlesAssigned(articles){
      let valid = true;
      console.log("article length: ", articles.length)
      if (articles.length > 0){
        articles.forEach($article => {
          console.log("basket item id", $article.roomId)
          if ($article.roomId == null){
            valid = false
          }
        })
      }
      return valid
    },
    anyArticlesAssigned(articles){
      let valid = false;
      console.log("article length: ", articles.length)
      if (articles.length > 0){
        articles.forEach($article => {
          console.log("basket item id", $article.roomId)
          if ($article.roomId != null){
            valid = true
          }
        })
      }
      return valid
    },
    articlesAssignedToRoom(articles, currentSelectedItemId){
      let valid = false;
      if (articles.length > 0){
        articles.forEach($article => {
          if ($article.roomId == currentSelectedItemId){
            valid = true
          }
        })
      }
      return valid
    },
    guestsFilledIn(room){
      let allFilled = true;
      room.guests.forEach(element => {
        if (element.firstname.length == 0 || element.lastname.length == 0 || (element.age && element.age.length == 0)){
          allFilled = false
        }
      });
      return allFilled;
    },
    displayGuestNames(guests, tripSummary=false){
      let guestArray = [];
      guests.forEach(element => {
        if (element.firstname.length > 0){
          guestArray.push(element.firstname)
        }
      });
      if (guestArray.length > 0){
        if (guestArray.length > 1){
            return `Guests: ${guestArray.join(', ')}`;
        }else{
            return `Guest: ${guestArray.join(', ')}`;
        }
         
      }else{
          if (tripSummary){
            return 'Guest details required'    
          } else {
            return 'Click to expand'
          }
          
      }
    },
    displayBookerName(){
      if (this.booker.firstname.length > 0 && this.booker.lastname.length > 0 && this.booker.cellNumber.length > 0){
        return `${this.booker.firstname} ${this.booker.lastname}, ${this.booker.cellNumber}`
      }else{
        return 'Enter booker details'
      }

    },
    bookerFilledIn(){
      let allFilled = true;
      if ((this.booker.isCompany == 'yes' && this.booker.companyName.length == 0) || this.booker.firstname.length == 0 || this.booker.lastname.length == 0 || this.booker.email.length == 0 || this.booker.cellNumber.length == 0){
        allFilled = false
      }
      return allFilled;
    },
    roomCalcPrice(rate){
      return rate.roomCount * rate.price.numeric / 100; 
    },
    roomPriceFormat(roomType){
      let price = this.roomCalcPrice(roomType.rate);
      let currency = roomType.rate.price.currency;
      return `${currency} ${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
    },
    articlePriceFormat(currency, price){
      let subTotal = price
      return `${currency} ${subTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
    },
    roomTotalPriceFormat(roomTypes){
      let currency = roomTypes[0].rate.price.currency;
      let price = this.totalAccommodationCost(roomTypes)
      if (this.applyLoyaltyPoints(price) && this.useLoyaltyPoints){
        price = 0.00;
      }
      return `<strong>${currency} ${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</strong>`
    },
    roomTotalPriceFormat_v2(roomTypes, articles){
      let currency = roomTypes[0].rate.price.currency;
      let price = this.totalAccommodationCost(roomTypes)
      console.log("total price: ", price)
      if (this.applyLoyaltyPoints(price) && this.useLoyaltyPoints){
        return `<s><strong>${currency} ${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</strong></s>`
      }else{
        return `<strong>${currency} ${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</strong>`
      }
      
    },
    refundableBookingAmountCalc(roomTypes){
      let currency = roomTypes[0].rate.price.currency;
      let price = this.totalAccommodationCost(roomTypes)
      this.refundableBookingAmount = Math.round((price * 0.08 + Number.EPSILON) * 100) / 100 ;
      return `${currency} ${this.refundableBookingAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
    },
    totalAccommodationCost(roomTypes){
      let price = 0;
      roomTypes.forEach(roomType => {
        price += this.roomCalcPrice(roomType.rate);
      });
      console.log("article length: ", this.articles.length)
      if (this.articles.length > 0){
        this.articles.forEach(article => {
          price += article['amount']
        })
      }
      return price;
    },
    qualifyingPoints(price){
      return Math.ceil(price);
    },
    applyLoyaltyPoints(totalPrice){
      if (this.availablePoints >= Math.ceil(totalPrice)){
        return true;
      }else{
        return false;
      }
    },
    isFree(){
      let totalCost = this.totalAccommodationCost(this.selectedRoomTypes);
      if (this.applyLoyaltyPoints(totalCost) && this.useLoyaltyPoints){
        return true;
      }else{
        return false;
      }
      
    },
    finalCost(){
      console.log("final_cost: ", this.totalAccommodationCost(this.selectedRoomTypes) - this.qualifyingPoints(this.totalAccommodationCost(this.selectedRoomTypes))  );
      console.log("total_cost: ", this.totalAccommodationCost(this.selectedRoomTypes)   );
      console.log("qual_point: ", this.totalAccommodationCost(this.selectedRoomTypes)   );
      return this.totalAccommodationCost(this.selectedRoomTypes) - this.qualifyingPoints(this.totalAccommodationCost(this.selectedRoomTypes)) ;
    },
    isPayable(){
      return !this.isFree()
    },
    gotoArticlesForm(){
        this.$emit('gotoArticlesForm')
    },
    displayCheckIn($date){
      return moment($date).format('DD MMM YYYY')
    },
    displayCheckOut($date){
      return moment($date).format('DD MMM YYYY')
    },
    willPayNow(){
      return this.payLater === 'no'
    },
    showArticleManageDialog(basketItem){
      this.manageArticlesDialog = true;
      console.log("selected basket: ", basketItem)
      this.currentSelectedItem = basketItem;
    },
    proceedToCheckout(){
      if (this.agreeToTerms){
        this.$emit('proceedToCheckout', 
            {
              booker: this.booker, 
              selectedRoomTypes: this.selectedRoomTypes, 
              searchParams: this.searchParams, 
              articles: this.articles,
              isFree: this.isFree(), 
              qualifyingPoints: (this.applyLoyaltyPoints(this.totalAccommodationCost(this.selectedRoomTypes)) && this.useLoyaltyPoints) ? this.qualifyingPoints(this.totalAccommodationCost(this.selectedRoomTypes)) : 0,
              refund_premium: this.refundableBookingAmount,
              refundable: (this.refundable === 'yes' && this.payLater !== 'yes') ? 'yes' : 'no',
              payLater: (!this.useLoyaltyPoints && this.payLater === 'yes') ? 'yes' : 'no'
            }
        )
      }else{
        this.snackbar.text = 'You must agree to the terms and conditions before you can continue!';
        this.snackbar.snackbar = true;
        this.isSearching = false
      }
     
    },
    
    addArticleFromBasketItem(item_id, article_id){
      let found = false;
      for (let i = 0; i < this.articles.length; i++){
        let $article = this.articles[i];
        if ($article['articleId'] == article_id && $article['roomId'] == null && found == false){
          found = true;
          i = this.articles.length
          $article.roomId = item_id;
          console.log("updating record", $article);
        }
      }
    },
    removeArticleFromBasketItem(item_id, article_id){
      let found = false
      for (let i = 0; i < this.articles.length; i++){
        let $article = this.articles[i];
        if ($article['articleId'] == article_id && $article['roomId'] == item_id && found == false){
          found = true
          i = this.articles.length
          $article.roomId = null;
        }
      }
    },
    
    processSelectedArticles(selectedArticles){
      let newArticles = []
      let k = 0;
      // if (selectedArticles.length > 0){
        selectedArticles.forEach($article => {
          for (let i = 0; i < $article['quantity']; i++){
            newArticles.push({amount: $article.amount, roomId: $article.roomId, currency: $article.currency, fileEntries: $article.fileEntries, id: $article.id, name: $article.name, quantity: $article.quantity});
            console.log("new articles: ", newArticles);
            newArticles[newArticles.length - 1].quantity = 1
            newArticles[newArticles.length - 1].articleId = k
            k += 1;
          }
        })
        return newArticles
      // } else {
        // return []
      // }
    }
  }
};
</script>
