<template>
    <v-card class="p3">
        <v-toolbar
            flat
            dark
        >
            <div class="d-flex">
                <v-img :eager="true" contain height="50" width="50"  src="/icons/icon-152x152.png"></v-img>
                <v-toolbar-title class="d-flex px-3 mt-3">  Guest Login</v-toolbar-title>  
            </div>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn depressed icon  @click="switchCancel()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-form
                ref="form"
                v-model="valid"
                class="pa-3"
            >
                <v-text-field
                    v-model="guest.email"
                    :rules="nameRules"
                    label="Email"
                    prepend-icon="mdi-email"
                    required
                    autocomplete='new'
                ></v-text-field>

                <v-text-field
                    v-model="guest.password"
                    :rules="passwordRules"
                    prepend-icon="mdi-form-textbox-password"
                    label="Password"
                    required
                    autocomplete='new-password'
                    :type="value ? 'password' : 'text'"
                    :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="() => (value = !value)"
                ></v-text-field>

                <v-card-actions class="text-center">
                    <v-container fluid>
                        <v-row>
                            <v-col>
                            <v-btn
                                block
                                large
                                :disabled="!valid"
                                color="primarycolor"
                                class="mx-auto rounded-pill"
                                @click="login"
                            >
                                Login
                            </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <span class="grey--text text-darken-1">
                                  Not yet registered? 
                                </span>
                                <span @click="switchSignup()" class='signup-btn'>Signup</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn
                                    depressed
                                    class="mx-auto"
                                    @click="switchForgotPassword()"
                                    text
                                    color="grey lighten-1"
                                    x-small
                                >
                                    Forgot your password?
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </v-form>
        </v-card-text>   
    </v-card>
</template>

<script>
export default {
    props:{
       swichedTo:Boolean,
    },
    data: () => ({
        value:String,
        guest:{
            email: '',
            password: ''            
        },
        valid: false,
        name: '',
        nameRules: [
            v => !!v || 'Name is required',
        ],
        password: '',
        passwordRules: [
            v => !!v || 'Password is required',
        ],
    }),
    created() {
    this.initialize();
    },
    watch: {
        swichedTo: function (val){
            if (val){
                this.guest.email = '';
                this.guest.password = '';
            }
        }
    },
    methods: {
        initialize() {
            //init code goes here
            
        },
        login(){
            let credentials = {
               email: this.guest.email,
               password: this.guest.password 
                
            }
            this.$emit('loginCredentials', credentials)
        },
        switchSignup(){
            this.$emit("switch", 'signup')
        },
        switchCancel(){
            this.$emit("switch", 'cancel')
        },
        switchForgotPassword(){
            this.$emit("switch", 'forgotPassword')
        },
        
    },
    

}
</script>
