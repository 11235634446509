<template>
    <div>
         <v-main >
            <!-- Provides the application the proper gutter -->
            <v-container style="max-width:800px;"> 
                <v-app-bar dark fixed  app>
                    <div class="d-flex"  @click="startAgain" style="cursor:pointer;">
                       <v-img :eager="true" contain height="50" width="50"  src="/icons/icon-152x152.png"></v-img>
                       <v-toolbar-title class="d-flex px-3 mt-3" >  Lion Roars Bookings</v-toolbar-title>
                    </div>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>Payment Complete</v-toolbar-title>  
                </v-app-bar>
                <v-card flat color="transparent" >
                    <v-card-text class="hide-scrollbar pa-0">
                        <v-row>
                            <v-col cols="12" class='pt-0' v-if="basket && basket.id">
                                <v-card class="booking-details elevated rounded-lg" flat>
                                    <v-card-title class="text-h5">
                                        Reservation
                                    </v-card-title>
                                    <v-card-subtitle v-if="!payLater">
                                        <strong>Status:</strong> {{ isFree ? 'Free' : 'Payment Successful' }} <v-icon color="primarycolor"> mdi-check-bold</v-icon>
                                        <br>
                                        <strong>Reason:</strong> {{isFree ? 'Redemption Of Earned Rands' : `${codeGroup}: ${codeDescription}`}} 
                                    </v-card-subtitle>
                                    <v-card-subtitle v-if="payLater">
                                      <strong>Status:</strong> Pay Later <v-icon color="primarycolor"> mdi-clock-bold</v-icon>
                                      <br>
                                      <strong>Reason:</strong> Agent opted to pay later.
                                    </v-card-subtitle>

                                    
                                    <v-card-text>

                                    </v-card-text>
                                    <v-card-text>
                                        <div class="booking-subdetails justify-space-between">
                                            <div>
                                                <span><v-icon small color="primarycolor">mdi-calendar-range</v-icon> {{from}} - {{to}}</span>
                                            </div>
                                            <div>
                                                <span><v-icon color="primarycolor" small>mdi-bed</v-icon> {{numberOfNightsText}}</span>
                                            </div>
                                             <div v-if="basket.booker.company && basket.booker.company.length > 0">
                                                <span><v-icon small color="primarycolor">mdi-account-settings</v-icon> {{basket.booker.company}}</span>
                                            </div>
                                            <div> 
                                                <span><v-icon small color="primarycolor">mdi-account-settings</v-icon> {{basket.booker.firstname}} {{basket.booker.lastname}}</span>
                                            </div>
                                        <div>
                                                <span><v-icon color="primarycolor" small>mdi-email</v-icon> {{basket.booker.email}}</span>
                                            </div>
                                        </div>
                                        <div class="booking-subdetails"  v-if="basket.notes">
                                            <div>
                                                <v-icon small color="primarycolor">mdi-note-outline</v-icon> {{basket.notes}}
                                            </div>
                                        </div>
                                    </v-card-text>
                                    <v-list >
                                        <v-subheader class="text-h6" v-html="hotelName"></v-subheader>
                                        <div  v-for="(item,index) in basket.basketItems" :key="index" >
                                          <v-subheader class="text-h6">Room</v-subheader>
                                          <v-list-item>
                                              <v-list-item-content class="text-left">
                                                  <v-list-item-title v-text="item.categoryDesc"></v-list-item-title>
                                                  <v-list-item-subtitle v-text="displayGuestNames(item.guests)"></v-list-item-subtitle>
                                                  <v-list-item-subtitle style="white-space:normal;"><strong>Reservation Number: {{item.reservationNumber}}</strong></v-list-item-subtitle>
                                              </v-list-item-content>
                                              <v-list-item-action>
                                                  <v-list-item-title v-text="roomPriceFormat(item.amount)"></v-list-item-title>
                                              </v-list-item-action>
                                          </v-list-item>
                                          <!--displaing extras per room-->
                                          <!--<div v-if="item.bookableItems && item.bookableItems.length > 0">-->
                                          <!--  <v-list-item v-for="(bookableItem, index1) in item.bookableItems" :key="index1">-->
                                          <!--    <v-list-item-content class="text-left">-->
                                          <!--      <v-list-item-title v-text="bookableItem.description"></v-list-item-title>-->
                                          <!--    </v-list-item-content>-->
                                          <!--    <v-list-item-action>-->
                                          <!--      <v-list-item-title v-text="roomPriceFormat(bookableItem.amount)"></v-list-item-title>-->
                                          <!--    </v-list-item-action>-->
                                          <!--  </v-list-item>-->
                                          <!--</div>-->
                                        </div>
                                        <div v-if="basket.basketItems[0].bookableItems && basket.basketItems[0].bookableItems.length > 0">
                                          <v-subheader class="text-h6">Extras</v-subheader>
                                          <v-list-item v-for="(bookableItem, index1) in basket.basketItems[0].bookableItems" :key="index1">
                                            <v-list-item-content class="text-left">
                                              <v-list-item-title v-text="bookableItem.description"></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                              <v-list-item-title v-text="roomPriceFormat(bookableItem.amount)"></v-list-item-title>
                                            </v-list-item-action>
                                          </v-list-item>
                                        </div>
                                        <v-list-item v-if="refundable"> 
                                            <v-list-item-content class="text-left">
                                                <v-list-item-title>Refundable Booking</v-list-item-title>
                                                <v-list-item-subtitle>Upgrade: This is a refundable booking.</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-list-item-title v-text="getRefundPremium()"></v-list-item-title>
                                            </v-list-item-action>
                                        </v-list-item>   
                                        <v-list-item v-if="!payLater">
                                            <v-list-item-content class="text-left"> 
                                                <v-list-item-title><strong>Amount Paid</strong></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-list-item-title ><strong>{{roomTotalPriceFormat(basket.basketItems)}}</strong></v-list-item-title> 
                                            </v-list-item-action>
                                        </v-list-item>
                                        <v-list-item v-if="payLater">
                                          <v-list-item-content class="text-left">
                                            <v-list-item-title><span style="color:red;"><strong>Amount Outstanding</strong></span></v-list-item-title>
                                          </v-list-item-content>
                                          <v-list-item-action>
                                            <v-list-item-title ><span style="color:red;"><strong>{{roomTotalPriceFormat(basket.basketItems)}}</strong></span></v-list-item-title>
                                          </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-card>

                                <v-card class="elevated rounded-lg" flat >
                                    <v-card-text>
                                        <p>To make any changes to your booking or where it is cancelled or changed by the provider, please contact our Customer Service Team at <a href="mailto:reservations@lionroars.com" target="_blank">reservations@lionroars.com</a></p>
                                        <div v-if="refundable">
                                            <h3>Refundable Booking</h3>
                                            <p>This is a refundable booking, so if you are unable to attend your booking due to unforeseen circumstances and can provide evidence as listed in the 
                                            <a href="https://refundable.me/extended/en" target="_blank" rel="noopener">Terms and Conditions here</a>, you may be entitled to a full refund.
                                            </p>
                                            <p>
                                            You will need your reference number <em><strong>{{transactionNumber}}</strong></em> 
                                            to <strong><u><a :href="getRefundUrl()" target="_blank"> apply for your refund using the form here</a></u></strong>
                                            </p>
                                        </div>
                                    </v-card-text>
                                </v-card>    
                            </v-col>
                        </v-row>

                        <v-card flat class="mt-3 py-2 elevated rounded-lg"  v-if="basket && basket.id">
                            <v-card-actions >
                                <v-btn class=" mx-auto" 
                                    dark
                                    color="primarycolor"
                                    rounded
                                    large
                                    @click="startAgain">
                                    Make another reservation
                                </v-btn>
                            </v-card-actions>
                        </v-card>

                    </v-card-text>
                </v-card>
                  
               
                
            </v-container>
         </v-main>

           <!-- notification bar -->
        <v-snackbar v-model="snackbar.snackbar" :timeout="snackbar.timeout" top rounded="pill" elevation="24">
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar.snackbar = false">
                Close
            </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import axios from "axios";
import moment from 'moment';
export default {
    props:{
       gatewayResponse: Object,
    },
    data: () => ({
        transaction: Object,
        basket: Object,
        snackbar:{
            snackbar:false,
            text:'',
            timeout: 3000
        },
        numberOfNightsText:String,
        hotelName: String,
        from: String,
        to: String,
        codeGroup: String,
        codeDescription: String,
        isFree: Boolean,
        payLater: Boolean,
        qualifyingPoints: Number,
        refundable: Boolean,
        refund_premium: Number,
        transactionNumber: String,
    
    }),
    created() {
    this.initialize();
    },
    methods: {
        initialize() {
            //init code goes here
            console.log(this.gatewayResponse);
           this.transaction = {}
           this.basket = {}
           if (this.gatewayResponse.basketId != undefined){
               this.getBasket();
           }else{
                this.getTransactionAndBasket();
           }
           
        },
        getBasket(){
            axios
            .get(window.Routes.get_basket_payments_path(), {
                params:{
                    format: 'json',
                    basketId: this.gatewayResponse.basketId,
                }
            })
            .then(response => {
                if (response.data.success){
                    this.basket = response.data.basket.data;
                    this.assignBasketVariables();
                    this.isFree = response.data.basket.is_free;
                    this.payLater = response.data.basket.pay_later;
                    this.qualifyingPoints = response.data.basket.qualifying_points  ;
                    this.refundable = response.data.basket.refundable
                    this.refund_premium = response.data.basket.refund_premium
                    console.log("Basket: ",response.data.basket);
                }else{
                    console.log(response.data.error);
                    this.snackbar.text = response.data.error.message;
                    this.snackbar.snackbar = true;
                    this.snackbar.timeout = 8000;
                    this.isSearching = false;
                }
            })
            .catch(error => {
                console.log(error);
                this.snackbar.text = 'Error occurred when trying to fetch reservation information.';
                this.snackbar.snackbar = true;
                this.snackbar.timeout = 8000;
                this.isSearching = false;
            });
        },
        getTransactionAndBasket(){
            axios
            .get(window.Routes.get_transaction_and_basket_payments_path(), {
                params:{
                    format: 'json',
                    transactionNumber: this.gatewayResponse.transactionNumber,
                }
            })
            .then(response => {
                if (response.data.success){
                    this.transaction = response.data.transaction.data.payment_status[response.data.transaction.data.payment_status.length - 1];
                    this.transactionNumber = response.data.transaction.transaction_number;
                    this.basket = response.data.basket.data;
                    this.isFree = response.data.basket.is_free;
                    this.payLater = response.data.basket.pay_later;
                    this.qualifyingPoints = response.data.basket.qualifying_points  ;
                    this.codeGroup = response.data.transaction.code_group;
                    this.codeDescription = response.data.transaction.code_description;
                    this.refundable = response.data.basket.refundable
                    this.refund_premium = response.data.basket.refund_premium
                    this.assignBasketVariables();

                    console.log("Transaction: ", response.data.transaction);
                    console.log("Basket: ",response.data.basket);
                }else{
                    console.log(response.data.error);
                    this.snackbar.text = response.data.error.message;
                    this.snackbar.snackbar = true;
                    this.isSearching = false;
                }
            })
            .catch(error => {
                console.log(error);
                this.snackbar.text = 'Error occurred when trying to fecth transaction and reservation information.';
                this.snackbar.snackbar = true;
                this.isSearching = false;
            });
        },
        getRefundUrl(){
            return `https://form.refundable.me/forms/refund?bookingReference=${this.transactionNumber}`
        },
        assignBasketVariables(){
                this.hotelName = this.basket.basketItems[0].mpename;
                this.from = this.displayCheckIn(this.basket.basketItems[0].from);
                this.to = this.displayCheckOut(this.basket.basketItems[0].to);
                this.numberOfNightsText = this.numberOfNightsShortText(this.basket.basketItems[0].from, this.basket.basketItems[0].to);
        },
         numberOfNightsShortText(from, to){
            let nightCount = this.calcNights(from,to);
            if (nightCount > 1){
                return `${nightCount} Nights`
            }else{
                return `${nightCount} Night`
            }
        },
        calcNights(from,to){
            let _to = moment(to);
            let _from = moment(from);
            return _to.diff(_from,'days');
        },
         displayGuestNames(guests){
            let guestArray = [];
            guests.forEach(element => {
                guestArray.push(element.firstname)
            });
            if (guestArray.length > 1){
                return `Guests: ${guestArray.join(', ')}`;
            }else{
                return `Guest: ${guestArray.join(', ')}`;
            }
        }, 
        roomPriceFormat(amount){
            let price = amount.numeric / 100;
            let currency = amount.currency;
            return `${currency} ${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
        },
        roomTotalPriceFormat(items){
            let currency = items[0].amount.currency;
            let price = 0;
            items.forEach(item => {
                price += item.amount.numeric / 100;
                if  (item.bookableItems != undefined && item.bookableItems.length > 0){
                    item.bookableItems.forEach(bookableItem => {
                        price += bookableItem['amount']['numeric'] / 100
                    })
                }
            });
            price = price - parseInt(this.qualifyingPoints);
            if (price <= 0){
                price = 0;
            }
            if (this.refundable){
                price +=  this.refund_premium;
            }

            return `${currency} ${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
        },
         getRefundPremium(){
            return `ZAR ${this.refund_premium.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
        },
        displayCheckIn($date){
            return moment($date).format('DD MMM YYYY')
        },
        displayCheckOut($date){
            return moment($date).format('DD MMM YYYY')
        }, 
        startAgain(){
              window.open(window.Routes.reservations_path(), '_self');
        }

    },
    

}
</script>
