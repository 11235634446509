<template>
  <div>
 
    <!-- Sizes your content based upon application components -->
    <v-main id="main-container"
     :style="{backgroundImage: 'url(' + backgroundImage + ')'}"
     
    >
      <!-- Provides the application the proper gutter -->
      <v-container style="max-width:800px;" >
        <!-- insert components here -->
        <reservation-booker-form v-bind:resultSet="bookerResult" v-bind:termsAndConditions="termsAndConditions"  @showTermsAndConditions="termsAndConditionsDialog = $event" @showRatalityTermsAndConditions="ratalityTermsAndConditionsDialog = $event" v-bind:isSearching="isSearching" v-bind:loggedInGuest="loggedInGuest" @gotoArticlesForm="bookerResult = {}" @proceedToCheckout="proceedToCheckout($event)" v-if="bookerResult.selectedRoomTypes" />
        <reservation-articles-form v-bind:resultSet="articlesResult"  @showPrivacyPolicy="privacyPolicyDialog = $event" @showTermsAndConditions="termsAndConditionsDialog = $event" @gotoBookerForm="gotoBookerForm($event)" @showRatalityTermsAndConditions="ratalityTermsAndConditionsDialog = $event" @gotoSelectForm="articlesResult = {}" v-else-if="articlesResult.articles && articlesResult.articles.length > 0" />
        <reservation-select-room-form v-bind:resultSet="searchResult" v-bind:isSearching="isSearching" v-bind:conservationLevy="conservationLevy"  @showPrivacyPolicy="privacyPolicyDialog = $event"  @showTermsAndConditions="termsAndConditionsDialog = $event" @showRatalityTermsAndConditions="ratalityTermsAndConditionsDialog = $event" @gotoQueryForm="searchResult = {}; searchParams = {};" @getArticles="getArticles($event)" v-else-if="searchResult.roomTypeResult && searchResult.roomTypeResult.length > 0" /> 
        <reservation-query-form v-bind:hotels="hotels" v-bind:isSearching="isSearching" v-bind:isGuestProcessing="isGuestProcessing" v-bind:externalHotelParams="externalHotelParams" @showPrivacyPolicy="privacyPolicyDialog = $event" @showTermsAndConditions="termsAndConditionsDialog = $event" @showRatalityTermsAndConditions="ratalityTermsAndConditionsDialog = $event" v-bind:queryParams="queryParams" @getSearchResults="getSearchResults($event)" @loginUser="loginUser($event)" @backgroundImage="backgroundImage=$event" v-else-if="!searchParams.hotelId && hotels && hotels.length > 0"  /> 
        <loading-spinner v-bind:loadingText="loadingText" v-else />

        <privacy-policy-dialog v-bind:privacyPolicy="privacyPolicy"  v-bind:privacyPolicyDialog="privacyPolicyDialog" @reversePrivacyPolicyFlag="privacyPolicyDialog = $event" />
        <terms-and-conditions-dialog v-bind:termsAndConditions="termsAndConditions"  v-bind:termsAndConditionsDialog="termsAndConditionsDialog" @reverseTermsAndConditionsFlag="termsAndConditionsDialog = $event" />

        <!-- <ratality-terms-and-conditions-dialog  v-bind:ratalityTermsAndConditions="ratalityTermsAndConditions" v-bind:ratalityTermsAndConditionsDialog="ratalityTermsAndConditionsDialog" @reverseRatalityTermsAndConditionsFlag="ratalityTermsAndConditionsDialog = $event" /> -->
  
      </v-container>
    </v-main>


    <!-- notification bar -->
    <v-snackbar v-model="snackbar.snackbar" :timeout="snackbar.timeout" top rounded="pill" elevation="24">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar.snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>

</template>

<script>
import axios from "axios";
import privacy_policy_dialog from './privacy_policy_dialog.vue';
export default {
  components: { privacy_policy_dialog },
  props:{
    externalSearchParams: Object,
    externalHotelParams: Object
  },
  data: () => ({
      //public and private variables go here
      title: 'Reservations',
      hotels: [],
      termsAndConditions: '',
      privacyPolicy: '',
      ratalityTermsAndConditions: '',
      conservationLevy: '',
      searchParams: {},
      searchResult: {},
      articlesResult: {},
      bookerResult: {},
      isSearching: false,
      isGuestProcessing: false,
      loadGuestForm: false,
      loadingText: 'loading',
      loggedInGuest: {},
      queryParams: {
        hotelId: Number,
        hotelName: String,
        location: String,
        locationDisplayName:String,
        instance: [],
        promocode: '',
        type: 'standardroom',
        countrooms: 1,
        occupancy: [],
        adultCount: Number,
        children1Count: Number,
        children2Count: Number,
        children3Count: Number,
        children4Count: Number,
        addBedCount: Number,
        addCotCount: Number,
        from: String,
        to: String
      },
      snackbar:{
        snackbar:false,
        text:'',
        timeout: 3000
      },
      backgroundImage: String,
      privacyPolicyDialog: false,
      termsAndConditionsDialog: false,
      ratalityTermsAndConditionsDialog: false,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {

      this.backgroundImage = '/imgs/Lion-Roars-background.jpg'

      this.queryParams.hotelId = null;
      this.queryParams.hotelName = '';
      this.queryParams.location = '';
      this.queryParams.locationDisplayName = '';
      this.queryParams.adultCount = 1;
      this.queryParams.children1Count = 0;
      this.queryParams.children2Count = 0;
      this.queryParams.children3Count = 0;
      this.queryParams.children4Count = 0;
      this.queryParams.addBedCount = 0;
      this.queryParams.addCotCount = 0
      this.queryParams.from = '';
      this.queryParams.to = '';

      if (this.externalSearchParams && this.externalSearchParams.hotelId){
        this.searchParams = this.externalSearchParams;
        this.loadingText = 'Finding available rooms...'
        this.getSearchResults(this.externalSearchParams);
      }else{
        this.loadingText = 'Loading query form...'
      }
    
      this.getHotels();
      
      
    },
    getHotels(){
      let currentGuestId = (localStorage.getItem('guestId') && localStorage.getItem('guestId').length > 0) ? localStorage.getItem('guestId') : null;
      axios
      .get(window.Routes.get_hotels_hotels_path(), {params: {format: 'json', guestId: currentGuestId}})
      .then(response => {
        console.dir(response);
        this.hotels = response.data.hotels;
        this.termsAndConditions = response.data.termsAndConditions;
        this.privacyPolicy = response.data.privacyPolicy;
        this.conservationLevy = response.data.conservationLevy;
        this.ratalityTermsAndConditions = response.data.ratalityTermsAndConditions;
        this.updateCurrentGuest(response.data.guest);   
      })
      .catch(error => {
        console.log(error);
      });
    },
    updateCurrentGuest(guest){

      if (guest && guest.guestName !== undefined){
        localStorage.setItem("guestId", guest.guestId)
        localStorage.setItem("guestName", guest.guestName)
        localStorage.setItem("pointBalance", guest.ratalityPointBalance)
        localStorage.setItem("guestPromocodes", guest.guestPromocodes)
        localStorage.setItem("guestIsAgent", guest.isAgent)
      }else{
        localStorage.clear("guestId");
        localStorage.clear("guestName");
        localStorage.clear("pointBalance");
        localStorage.clear("guestPromocodes");
        localStorage.clear("guestIsAgent");
      }
    },
    getSearchResults(searchParams){
      console.log("params: ",searchParams);
      this.isSearching = true;
      this.queryParams = searchParams;
      axios
      .post(window.Routes.post_search_query_hotels_path(), {
        format: 'json',
        searchParams,
        guestId: (localStorage.getItem('guestId') && localStorage.getItem('guestId').length > 0) ? localStorage.getItem('guestId') : null
      })
      .then(response => {
        console.log(response);
        if (response.data.success){
          if (response.data.roomTypeResult && response.data.roomTypeResult.length > 0){
            this.searchResult = response.data;
            this.backgroundImage = '/imgs/none.png';
          }else{
            this.snackbar.text = 'No rooms available.';
            this.snackbar.snackbar = true;
            this.searchResult = {};
            this.searchParams = {};
          }
        }else{
          this.snackbar.text = response.data.error.messages;
          this.snackbar.snackbar = true;
          this.snackbar.timeout = 10000;
          this.searchResult = {};
        }
       
        this.isSearching = false;
      })
      .catch(error => {
        console.dir(error);
        this.snackbar.text = "The search took to long. Please try again with a smaller head count or contact central reservations for assistance.";
        this.snackbar.snackbar = true;
        this.snackbar.timeout = 8000;
        this.searchResult = {};
        this.isSearching = false;
      });
    },
    getArticles(rateSelectionParams){
      this.isSearching = true
      
      axios
      .post(window.Routes.post_search_articles_hotels_path(), {
        format: 'json',
        rateSelectionParams,
        guestId: (localStorage.getItem('guestId') && localStorage.getItem('guestId').length > 0) ? localStorage.getItem('guestId') : null
      })
      .then(response => {
        console.log(response.data);
       
        this.isSearching = false;

        if (response.data.articles && response.data.articles.length > 0 ){
          this.articlesResult = {
            articles: response.data.articles,
            selectedRoomTypes: response.data.selectedRoomTypes,
            searchParams: response.data.searchParams
          }
        }else{
          this.articlesResult = {};
          this.bookerResult = {
            selectedRoomTypes: response.data.selectedRoomTypes,
            searchParams: response.data.searchParams
          };
          this.loggedInGuest = response.data.guest;
        }

      })
      .catch(error => {
        console.log(error);
        this.snackbar.text = 'Error occurred. Please try again.';
        this.snackbar.snackbar = true;
        this.articlesResult = {};
        this.isSearching = false;
      });
    },
    gotoBookerForm(articleSelectionParams){
      this.loadGuestForm = true
      this.isSearching = true
      console.log("reservations.vue -> gotoBookerForm")
      console.log(articleSelectionParams)
      axios
      .post(window.Routes.post_articles_hotels_path(), {
        format: 'json',
        articleSelectionParams,
        guestId: (localStorage.getItem('guestId') && localStorage.getItem('guestId').length > 0) ? localStorage.getItem('guestId') : null
      })
      .then(response => {
        console.log(response.data);
       
        this.isSearching = false;

        if (response.data.articles && response.data.articles.length > 0 ){
          console.log("within IF")
          this.bookerResult = {
            articles: response.data.articles,
            selectedRoomTypes: response.data.selectedRoomTypes,
            searchParams: response.data.searchParams
          }
        }else{
          this.articlesResult = {};
          this.bookerResult = {
            selectedRoomTypes: response.data.selectedRoomTypes,
            searchParams: response.data.searchParams
          };
          this.loggedInGuest = response.data.guest;
        }
        console.log("bookerREesult: ", this.bookerResult)
      })
      .catch(error => {
        console.log(error);
        this.snackbar.text = 'Error occurred. Please try again.';
        this.snackbar.snackbar = true;
        this.articlesResult = {};
        this.isSearching = false;
      });
      // this.bookerResult = articleSelectionParams
    },
    proceedToCheckout(bookerParams){
      this.isSearching = true
      axios
        .post(window.Routes.post_finalize_basket_hotels_path(), {
            format: 'json',
            selectedRoomTypes: bookerParams.selectedRoomTypes,
            searchParams: bookerParams.searchParams,
            articles: bookerParams.articles,
            booker: bookerParams.booker,
            qualifyingPoints: bookerParams.qualifyingPoints,
            isFree: bookerParams.isFree,
            refundable: bookerParams.refundable,
            refund_premium: bookerParams.refund_premium,
            payLater: bookerParams.payLater
        })
        .then(response => {
          if (response.data.success){
            this.preparePayment(response.data.basketId);
          }else{
            console.log(response.data.error.messages);
            this.snackbar.text = response.data.error.messages;
            this.snackbar.snackbar = true;
            this.isSearching = false;
          }
          
        })
        .catch(error => {
          console.log(error);
          this.snackbar.text = 'Error occurred when finalizing basket.';
          this.snackbar.snackbar = true;
          this.isSearching = false;
        });
     
    },
    preparePayment(basketId){
      this.isSearching = true
       
      axios
      .post(window.Routes.post_prepare_checkout_payments_path(), {
          format: 'json',
          basketId: basketId,
      })
      .then(response => {
        if (response.data.success){
            window.open(response.data.checkout_path , '_self');
        }else{
          this.snackbar.text = response.data.message;
          this.snackbar.snackbar = true;
          this.isSearching = false;
        }
      })
      .catch(error => {
        console.log(error);
        this.snackbar.text = 'Error occurred when preparing checkout.';
        this.snackbar.snackbar = true;
        this.isSearching = false;
      });
    },
  }
}
</script>



