import Vue from 'vue/dist/vue.esm';
import Vuetify from 'vuetify/dist/vuetify.min'
import Axios from 'axios/dist/axios.min';
import VueAxios from 'vue-axios/dist/vue-axios.min';

Vue.use(Vuetify)
Vue.use(VueAxios, Axios);

import '../filters/index.js.erb';
import '../components/index.js.erb';

document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('app');
  if (element != null) {
    const app = new Vue({
       vuetify: new Vuetify()
    }).$mount(element);
  }
});
