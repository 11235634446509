<template>
  <div>
    <app-bar-nav-drawer/>

    <!-- Sizes your content based upon application components -->
    <v-main class="mt-11">
      <!-- Provides the application the proper gutter -->
      <v-container fluid> 
        <!-- insert components here -->
        <user/>
      </v-container>
    </v-main>

    <bottom-nav/>
  </div>

</template>

<script>
import axios from "axios";
export default {
  data: () => ({
      //public and private variables go here
     
    
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      //init code goes here.
    }
  }
}
</script>
