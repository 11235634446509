import Vue from 'vue/dist/vue.esm';


  
  import CheckoutReturnFromGateway from "/tmp/build/app/javascript/components/checkout_return_from_gateway.vue";
  Vue.component("checkout-return-from-gateway", CheckoutReturnFromGateway);

  
  import PasswordResetForm from "/tmp/build/app/javascript/components/password_reset_form.vue";
  Vue.component("password-reset-form", PasswordResetForm);

  
  import CheckoutComplete from "/tmp/build/app/javascript/components/checkout_complete.vue";
  Vue.component("checkout-complete", CheckoutComplete);

  
  import CheckoutForm from "/tmp/build/app/javascript/components/checkout_form.vue";
  Vue.component("checkout-form", CheckoutForm);

  
  import CheckoutReturnFromPayflex from "/tmp/build/app/javascript/components/checkout_return_from_payflex.vue";
  Vue.component("checkout-return-from-payflex", CheckoutReturnFromPayflex);

  
  import AssistanceDialog from "/tmp/build/app/javascript/components/assistance_dialog.vue";
  Vue.component("assistance-dialog", AssistanceDialog);

  
  import ReservationQueryForm from "/tmp/build/app/javascript/components/reservation_query_form.vue";
  Vue.component("reservation-query-form", ReservationQueryForm);

  
  import BottomNav from "/tmp/build/app/javascript/components/bottom_nav.vue";
  Vue.component("bottom-nav", BottomNav);

  
  import User from "/tmp/build/app/javascript/components/user.vue";
  Vue.component("user", User);

  
  import ReservationBookerForm from "/tmp/build/app/javascript/components/reservation_booker_form.vue";
  Vue.component("reservation-booker-form", ReservationBookerForm);

  
  import LoadingSpinner from "/tmp/build/app/javascript/components/loading_spinner.vue";
  Vue.component("loading-spinner", LoadingSpinner);

  
  import VueTelInputVuetify from "/tmp/build/app/javascript/components/vue-tel-input-vuetify.vue";
  Vue.component("vue-tel-input-vuetify", VueTelInputVuetify);

  
  import RatalityTermsAndConditionsDialog from "/tmp/build/app/javascript/components/ratality_terms_and_conditions_dialog.vue";
  Vue.component("ratality-terms-and-conditions-dialog", RatalityTermsAndConditionsDialog);

  
  import GuestSignup from "/tmp/build/app/javascript/components/guest_signup.vue";
  Vue.component("guest-signup", GuestSignup);

  
  import CheckoutRetry from "/tmp/build/app/javascript/components/checkout_retry.vue";
  Vue.component("checkout-retry", CheckoutRetry);

  
  import GuestForgotPassword from "/tmp/build/app/javascript/components/guest_forgot_password.vue";
  Vue.component("guest-forgot-password", GuestForgotPassword);

  
  import PrivacyPolicyDialog from "/tmp/build/app/javascript/components/privacy_policy_dialog.vue";
  Vue.component("privacy-policy-dialog", PrivacyPolicyDialog);

  
  import ReservationArticlesForm from "/tmp/build/app/javascript/components/reservation_articles_form.vue";
  Vue.component("reservation-articles-form", ReservationArticlesForm);

  
  import GuestEdit from "/tmp/build/app/javascript/components/guest_edit.vue";
  Vue.component("guest-edit", GuestEdit);

  
  import TermsAndConditionsDialog from "/tmp/build/app/javascript/components/terms_and_conditions_dialog.vue";
  Vue.component("terms-and-conditions-dialog", TermsAndConditionsDialog);

  
  import Reservations from "/tmp/build/app/javascript/components/reservations.vue";
  Vue.component("reservations", Reservations);

  
  import ReservationSelectRoomForm from "/tmp/build/app/javascript/components/reservation_select_room_form.vue";
  Vue.component("reservation-select-room-form", ReservationSelectRoomForm);

  
  import BaseVueTemplate from "/tmp/build/app/javascript/components/base_vue_template.vue";
  Vue.component("base-vue-template", BaseVueTemplate);

  
  import GuestLogin from "/tmp/build/app/javascript/components/guest_login.vue";
  Vue.component("guest-login", GuestLogin);

  
  import AppBarNavDrawer from "/tmp/build/app/javascript/components/app_bar_nav_drawer.vue";
  Vue.component("app-bar-nav-drawer", AppBarNavDrawer);

  
  import GuestDialog from "/tmp/build/app/javascript/components/guest_dialog.vue";
  Vue.component("guest-dialog", GuestDialog);
