
<template>
  <div v-if="articles"> 
    <v-app-bar 
      dark 
      fixed 
      app>
        <v-btn icon @click="gotoSelectForm">
            <v-icon>mdi-keyboard-backspace</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title >Extras</v-toolbar-title>  
        <v-menu
          left
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="menuClick()"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-card color="primarycolor" dark depressed elevation="0" v-if="loggedInGuestId && loggedInGuestId.length" class="profilepanel rounded-0">
            <v-card-title class="text-subtitle-2 white--text">{{loggedInGuestName}}</v-card-title>
            <v-card-subtitle class="text-caption">Rands Earned:<br>ZAR {{loggedInGuestPoints}}.00</v-card-subtitle>
          </v-card>
          <v-list dense class="profilelist">
            <v-list-item>
              <v-list-item-icon>
                <v-icon >mdi-help-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="assistanceDialog = true">Need Assistance</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item style="cursor:default;">
              <v-list-item-icon>
                <v-icon >mdi-application</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="$emit('showPrivacyPolicy', true);">Privacy Policy</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item style="cursor:default;">
              <v-list-item-icon>
                <v-icon >mdi-application</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="$emit('showTermsAndConditions', true);">Terms and Conditions</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Payments are secured by
                  <a href="https://www.peachpayments.com/#/products/payments" target="_blank">
                     <v-img src="peach_payment_logo_square.png" width="140" style="margin: auto;"></v-img>
                  </a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
    </v-app-bar>
    
    <!--<v-card class="mt-3 my-3" flat>-->
    <!--  <v-card-title v-text="searchParams.name"></v-card-title>-->
    <!--  <v-card-subtitle class="text-center">-->
    <!--    <span>Please Note: All extra items have to be assigned to a room on the booking form</span>-->
    <!--  </v-card-subtitle>-->
    <!--</v-card>-->
    
    
    <!--                                    start showing article cards                                      -->
     <v-card color="transparent" >
      <v-card-text class="hide-scrollbar pa-0">
        <v-card flat class="booking-details" >
          <v-card-title v-html="searchParams.hotelName"></v-card-title>
          <v-card-subtitle>
            <v-icon small color="primarycolor">mdi-calendar-range</v-icon> {{displayCheckIn(searchParams.from)}} - {{displayCheckOut(searchParams.to)}}
          </v-card-subtitle>
          <v-card-text>
            <div class="booking-subdetails d-flex justify-space-between">
              <div>
                <span><v-icon color="primarycolor" small>mdi-human-male</v-icon>{{searchParams.adultCount}}</span><span v-if="searchParams.childrenCount > 0" class="ml-2"><v-icon color="primarycolor" small>mdi-human-male-boy</v-icon> {{searchParams.childrenCount}}</span>
              </div>
              <div>
                <span><v-icon color="primarycolor" small>mdi-bed</v-icon> {{numberOfNightsShortText()}}</span>
              </div>
            </div>
          </v-card-text>
        </v-card>

        <v-container fluid>
          <v-row>
            <v-col cols="12" v-for="article in articles" :key="article.id" class=" info-box">

              <v-card class="rounded-md elevated">
                <v-carousel height="300" hide-delimiters>
                  <!--<v-chip color="pinkgradient" class="white--text text-uppercase pinkgradient overlay-chip">-->
                  <!--  {{getRoomsLeftLabel(roomType)}}-->
                  <!--</v-chip>-->
                  <v-carousel-item
                    v-for="(image, i) in article.fileEntries"
                    :key="i"
                    :src="image.url"
                    :eager="true"
                  ></v-carousel-item>
                  <v-img src="https://via.placeholder.com/600x250?text=No+Image" height="300px" v-if="!article.fileEntries || article.fileEntries.length == 0 "></v-img>
                  <!--<v-card class="" style="position:relative" color="rgb(39,39,39,0.4" height="41px">-->
                  <!--  <v-card-title class="white--text text-uppercase mt-n11 px-6 pt-2"  >-->
                      
                  <!--  </v-card-title>-->
                  <!--</v-card>-->
                  <div class="room-title">
                    {{article.name}}
                  </div>
                </v-carousel>
                <v-list >
                  <v-list-item>
                    <v-list-item-content style="width:70%;">
                      <v-list-item-title>
                        Price
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="text-center" style="display:block;">
                      <v-list-item-title>
                        <!--Rooms-->
                      </v-list-item-title>
                    </v-list-item-action>
                  </v-list-item>
                  <template>
                    <v-list-item>
                      <v-list-item-content style="width:70%;">
                        <v-list-item-title>
                          <v-list-item-subtitle></v-list-item-subtitle>
                        </v-list-item-title>
                        <v-list-item-subtitle v-html="formatPrice(article.currency, article.amount)"><small>{{article.fileEntries.subtitle}}</small></v-list-item-subtitle>
                        <!--<v-list-item-subtitle><strike v-if="rate.price.value != rate.rackRate">{{rate.price.currency}} {{rate.rackRate}}</strike><br v-if="rate.price.value != rate.rackRate"><small>{{rate.rateDescription}}</small></v-list-item-subtitle>-->
                      </v-list-item-content>
                      <v-list-item-action class="d-flex align-center justify-center flex-row">
                        <v-list-item-subtitle><strong>Quantity:</strong> </v-list-item-subtitle>
                        <v-text-field
                          ref="quantity"
                          hide-details
                          single-line
                          type="number"
                          style="width: 60px;"
                          min='1'
                          :value="1"
                          :rules="[value => value >= 0 || 'Value must be greater than or equal to 0']"s
                        />
                      </v-list-item-action>
                      <v-list-item-action class="d-flex align-center justify-center flex-row">
                        <v-btn   color="secondarycolor" small dark rounded  @click="increaseArticlesCount(article, $event.currentTarget.parentNode.parentNode.querySelector('input[type=number]').value)">Add</v-btn> 
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <v-list-item>
                    <!--<v-list-item-subtitle><v-icon color="primarycolor" small>mdi-qrcode</v-icon> <small>{{getPromoCode(roomType)}}</small></v-list-item-subtitle>-->
                  </v-list-item>
                  
                </v-list>
              </v-card>

            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    
    
    <v-fab-transition>
      <v-btn
        @click="sheetBasket = !sheetBasket"
        dark
        color="primarycolor"
        fixed
        bottom
        fab
        elevation="10"
        class="arrow-button"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-dialog
      v-model="isSearching"
      
      persistent
      width="300"
      >
      <v-card
          color="primarycolor"
          dark
      >
          <v-card-text>
           <div class="py-1">Processing selection...</div>
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
          </v-card-text>
      </v-card>
    </v-dialog>
    <assistance-dialog v-bind:assistanceDialog="assistanceDialog"  @reverseFlag="assistanceDialog = $event" />
    <v-bottom-sheet 
      v-model="sheetBasket"
      inset
    >
      <v-sheet style="border-radius: 5px 5px 0 0;">
        <div class="pb-3">
          <div class="accommodate-container"><div class="accommodate" style="overflow: none; text-overflow: none; white-space: normal; font-weight-bold" v-html="getDisplayMessage()"></div></div>
            <v-list dense>

              <v-list-item class="justify-space-between">
                  <h4 class="flex-grow-1">Summary</h4>
                  <!-- <v-btn
                    depressed
                    dense
                    icon
                    @click="sheetBasket = !sheetBasket"
                    color="primarycolor"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn> -->
                
              </v-list-item>
              
               <template v-for="selectedArticle in articles" >
                 <template v-if="selectedArticle['quantity'] > 0">
                  <v-list-item>
                    <v-list-item-icon style="margin-right:10px!important;">
                      <v-btn  class="mx-2" depressed dense icon x-small
                            color="red" @click="removeAllArticles(selectedArticle)" v-show="articleCount(selectedArticle)">
                          <v-icon dark v-if="articleCount(selectedArticle) > 0">mdi-close</v-icon>
                      </v-btn> 
                    </v-list-item-icon>
                    <v-list-item-content class="text-left">         
                      <v-list-item-title v-html="articleBreadown(selectedArticle)"></v-list-item-title>
                    </v-list-item-content>
                  
                    <v-list-item-action>
                      <v-list-item-title><span class="summary-room-price">{{articlePrice(selectedArticle)}}</span></v-list-item-title>
                    </v-list-item-action>
                    
                  </v-list-item>  
                </template>
              </template>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content class="text-left"> 
                  <v-list-item-title><h3>Total</h3></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-title ><h3>{{selectedArticlesPriceFormat(articles)}}</h3></v-list-item-title> 
                </v-list-item-action>
              </v-list-item>
             
              <v-list-item>
                <v-list-item-content>
                  <div class="text-center">
                    <v-btn
                      
                      dark
                      rounded
                      
                      color="secondarycolor"
                      @click="sheetBasket = !sheetBasket"  
                      class="mx-auto"
                    >
                      Add More Items</v-btn>
                    <v-btn
                      
                      dark
                      rounded
                      
                      color="secondarycolor"
                      @click="gotoBookerForm()"
                      :loading="loading == isSearching" 
                    >
                      Proceed</v-btn>
                  </div>    
                </v-list-item-content>
              </v-list-item >
              
            </v-list>
            <div class="assistance">Please contact our team if you need any assistance</div>
          
        </div>
      </v-sheet>
    </v-bottom-sheet>
    
    
  </div>
</template>

<script>

import moment from 'moment';

export default {
  props: {
    resultSet: Object,
    isSearching: Boolean
  },
  data: () => ({
    articles: Array,  
    roomTypes: Object,
    searchParams: Object,
    sheetBasket: false,
    loading: true,
    itemCount: 0,
    articleSelection: [],
    assistanceDialog: false,
    loggedInGuestId: String,
    loggedInGuestName: String,
    loggedInGuestPoints: String,
    loggedInGuestPromocodes: String,
    numberOfNights: Number,
  }),
  mounted() {
    window.onpopstate = event => {
      if (this.sheetBasket){
        // close basketSheet onbrowser back button
        history.pushState(null, document.title, location.href);
        this.sheetBasket = false;
      }else{
        this.$emit('gotoQueryForm')
      }
       
    };
  },
  created() {
    history.pushState(null, document.title, location.href);
    this.initialize();
  },
  watch:{
    sheetBasket: function(newVal, oldVal){
      if (newVal && !oldVal){
        history.pushState(null, document.title, location.href);
      }
    }
    
  },
  methods: {
    initialize() {
      //init method code goes here
      console.log("within the artices script")
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
      });
      this.articles = this.resultSet.articles;
      this.roomTypes =  this.resultSet.selectedRoomTypes;
      this.searchParams = this.resultSet.searchParams;
      console.log("room types: ", this.roomTypes)
      this.numberOfNights = this.roomTypes[0].rate.days.length
    },
    gotoSelectForm(){
      this.$emit('gotoSelectForm')
    },
    gotoBookerForm(){
      this.articleSelection = [];
      this.articles.forEach($article => {
        if ($article['quantity'] > 0){
          this.articleSelection.push($article);
        }
      });
      this.$emit('gotoBookerForm', {articles: this.articleSelection, selectedRoomTypes: this.roomTypes, searchParams: this.searchParams})
    },
    displayCheckIn($date){
      return moment($date).format('DD MMM YYYY')
    },
    displayCheckOut($date){
      return moment($date).format('DD MMM YYYY')
    },
    menuClick(){
      this.loggedInGuestId = (localStorage.getItem('guestId') && localStorage.getItem('guestId').length > 0) ? localStorage.getItem('guestId') : null;
      this.loggedInGuestName = (localStorage.getItem('guestName') && localStorage.getItem('guestName').length > 0) ? localStorage.getItem('guestName') : null;
      this.loggedInGuestPoints = (localStorage.getItem('pointBalance') && localStorage.getItem('pointBalance').length > 0) ? localStorage.getItem('pointBalance') : null;
      this.loggedInGuestPromocodes = (localStorage.getItem('guestPromocodes') && localStorage.getItem('guestPromocodes').length > 0) ? localStorage.getItem('guestPromocodes') : null;
    },
    increaseArticlesCount(article, quantity){
      quantity = parseInt(quantity)
      console.log("increase quantity: ", quantity)
      this.sheetBasket = !this.sheetBasket
      let record = this.articles.find((r) => r.id === article['id']);
      if (record) {
        record.roomId = this.roomTypes[0].id
        record.quantity += quantity;
        this.itemCount += quantity;
      }
      console.log("updated articles", this.articles)
    },
    decreaseArticles(article){
      let record = this.articles.find((r) => r.id === article['id']);
      if (record) {
        record.quantity -= 1;
        this.itemCount -= 1;
      }
      console.log("updated articles", this.articles)
    },
    removeAllArticles(article){
      for (let i = 0; i < this.articles.length; i++){
        if (this.articles[i].id === article['id']){
          this.articles[i]['quantity'] = 0
          console.log(this.articles)
        }
      }
    },
    articleBreadown(selectedArticle){
      return `<span style="font-size:14px;">${selectedArticle.name}</span> </br> <span style="color:darkgrey;font-size:14px;margin-right:5px;"> (Quantity: ${selectedArticle.quantity})</span> `
    },
    selectedArticlesPriceFormat(articles){
      let currency = this.articles[0].currency;
      let price = 0;
      articles.forEach($article => {
        if ($article['quantity'] > 0) {
          price += $article['amount'] * $article['quantity']
        }
      });
      return `${currency} ${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
    },
    articleCount(article){
      return article.quantity
    },
    articlePrice(article){
      let price = 0;
      console.log(article)
      if (article['quantity'] > 0){
        price = article['amount'] * article['quantity']
      }
      return `${article['currency']} ${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
    },
    getDisplayMessage(){
      return "<span>Click 'proceed' to continue to the booking form or add more items.</span>"
    },
    formatPrice(currency, price){
      return `<strong>${currency} ${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}<strong>`
    },
    numberOfNightsShortText(){
      if (this.numberOfNights > 1){
        return `${this.numberOfNights} Nights`
      }else{
        return `${this.numberOfNights} Night`
      }
    },
  }
};
</script>
