<template>
    <div class="text-center">
        <v-dialog
        v-model="dialog"
        max-width="500"
        scrollable
        >
           <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn
                color="white"
                class="primarycolor--text signup-btn"
                v-bind="attrs"
                v-on="on"
                rounded
                @click="dialogOpen = true;"
                :style="{display: showLoginLabel ? 'block' : 'none'}"
                >
                    <v-icon class="mr-1">mdi-pencil</v-icon> Sign Up or Login
                </v-btn>
            </template> -->

        <guest-login v-if="login" @switch="switchGuestDialog($event)" @loginCredentials="sendLoginCredentials($event)" v-bind:switchedTo="login"/>
        <guest-signup v-if="signup" @switch="switchGuestDialog($event)" @signupCredentials="sendSignupCredentials($event)" v-bind:switchedTo="signup"/>
        <guest-forgot-password v-if="forgotPassword" @switch="switchGuestDialog($event)" @forgotPasswordEmail="sendForgotPasswordEmail($event)" v-bind:switchedTo="forgotPassword" />    
        <guest-edit v-if="guestEdit" @switch="switchGuestDialog($event)" v-bind:guestProfile="guestProfile" @editCredentials="sendEditCredentials($event)" v-bind:switchedTo="guestEdit"/>

        </v-dialog>

        <!-- notification bar -->
        <v-snackbar v-model="snackbar.snackbar" :timeout="snackbar.timeout" top rounded="pill" elevation="24">
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar.snackbar = false">
            Close
            </v-btn>
        </template>
        </v-snackbar>
    </div>
</template>

<script>
import guest_forgot_password from './guest_forgot_password.vue';
import axios from "axios";
export default {
  components: { guest_forgot_password },
    props:{
      guestDialogOpen:Boolean,
      noCurrentUser: Boolean,
      guestEditProfile: Boolean,
    },
    data: () => ({
        dialog: false,
        login:true,
        signup:false,
        forgotPassword:false,
        guestEdit:false,
        guestProfile: Object,
        showLoginLabel: Boolean,
        dialogOpen: false,
        snackbar:{
            snackbar:false,
            text:'',
            timeout: 3000
        },
    }),
    created() {
    this.initialize();
    },
    watch: {
        guestDialogOpen: function (newVal, oldVal) {    
            this.signup = false;
            this.forgotPassword = false;
            this.guestEdit = false;
            this.login = true;
            if (!oldVal && newVal){
                this.dialog = true;
                this.$emit('reverseFlag', false);
            }
        },
        dialogOpen: function (newVal, oldVal) {    
            this.signup = false;
            this.forgotPassword = false;
            this.guestEdit = false;
            this.login = true;
            if (!oldVal && newVal){
                this.dialog = true;
                this.$emit('reverseFlag', false);
                this.dialogOpen = false;
            }
        },
        guestEditProfile: function (newVal, oldVal) {    
            this.signup = false;
            this.forgotPassword = false;
            this.login = false;
            this.guestEdit = true;
            if (!oldVal && newVal){
                this.dialog = true;
                this.$emit('reverseEditFlag', false);
                this.getGuest();
            }
        },
        noCurrentUser: function (newVal, oldVal) {    
            
            this.showLoginLabel = newVal;
            console.log("showLoginLabel: ", this.showLoginLabel);
           
        },
    },
    methods: {
        initialize() {
            //init code goes here
            this.showLoginLabel = this.noCurrentUser;
        },
        switchGuestDialog(goto){
            this.login = false      
            this.signup = false
            this.forgotPassword = false
            this.guestEdit = false;
            if (goto == 'login'){
                this.login = true
            } else if( goto == 'signup'){
                this.signup = true
            } else if( goto == 'forgotPassword'){
                this.forgotPassword = true
            } else {
                this.dialog = false
            } 
            this.$emit('reverseFlag', false); 
            this.$emit('reverseEditFlag', false);
        },
        
        sendLoginCredentials(userCredentials){
            this.isGuestProcessing = true
            this.$emit('guestProcessing', this.isGuestProcessing);

            axios
            .post(window.Routes.post_login_user_users_path(), {
                format: 'json',
                Logintype: "MemberLogin",
                login: userCredentials.email,
                password: userCredentials.password
            })
            .then(response => {
                this.isGuestProcessing = false
                this.$emit('guestProcessing', this.isGuestProcessing);

                if (response.data.success){
                    localStorage.setItem("guestId", response.data.data.guestId)
                    localStorage.setItem("guestName", response.data.data.guestName)
                    localStorage.setItem("pointBalance", response.data.data.ratalityPointBalance)
                    localStorage.setItem("guestPromocodes", response.data.data.guestPromocodes)
                    localStorage.setItem("guestIsAgent", response.data.data.isAgent)

                    this.switchGuestDialog('close');

                    this.$emit('snackBarTrigger', {text:  `Hi ${response.data.data.guestName}. Welcome back!`, duration:5000});
                    this.$emit('setNoCurrentUser', false);
                }else{
                    this.clearLocalStorage();
                    this.$emit('snackBarTrigger', {text:  response.data.message, duration:5000});
                    this.snackbar.snackbar = true;
                }
            })
            .catch(error => {
                this.clearLocalStorage();
                this.$emit('snackBarTrigger', {text:  error.message, duration:8000});
                this.isGuestProcessing = false;
                this.$emit('guestProcessing', this.isGuestProcessing);
            });
        },
        sendSignupCredentials(userCredentials){
            this.isGuestProcessing = true
            this.$emit('guestProcessing', this.isGuestProcessing);

            axios
            .post(window.Routes.post_create_user_users_path(), {
                format: 'json',
                guest: userCredentials
            })
            .then(response => {
                this.isGuestProcessing = false
                this.$emit('guestProcessing', this.isGuestProcessing);

                if (response.data.success){
                    localStorage.setItem("guestId", response.data.data.guestId)
                    localStorage.setItem("guestName", response.data.data.guestName)
                    localStorage.setItem("pointBalance", response.data.data.ratalityPointBalance)
                    localStorage.setItem("guestPromocodes", response.data.data.guestPromocodes)
                    localStorage.setItem("guestIsAgent", response.data.data.isAgent)
                    this.switchGuestDialog('close');

                    this.$emit('snackBarTrigger', {text:`Hi ${response.data.data.guestName}. Welocme to Lion Roars Bookings!`, duration:5000});
                    this.$emit('setNoCurrentUser', false);
                }else{
                    this.clearLocalStorage();
                    this.$emit('snackBarTrigger', {text:response.data.message, duration:10000});
                }
            })
            .catch(error => {
                this.clearLocalStorage();
                this.$emit('snackBarTrigger', {text: error.message, duration:10000});
                this.isGuestProcessing = false;
                this.$emit('guestProcessing', this.isGuestProcessing);
            });
        },
        getGuest(){
            this.isGuestProcessing = true
            this.$emit('guestProcessing', this.isGuestProcessing);
            axios
            .get(window.Routes.get_guest_users_path(), {params: {format: 'json', guestId: localStorage.getItem('guestId')}})
            .then(response => {

                this.isGuestProcessing = false
                this.$emit('guestProcessing', this.isGuestProcessing);
                if (response.data.success){
                    this.guestProfile = response.data.guest;   
                    console.log("get guest: ", this.guestProfile );
                    this.$emit('setNoCurrentUser', false);
                }else{
                    this.clearLocalStorage();
                    this.$emit('snackBarTrigger', {text: response.data.message, duration:5000});
                }

            })
            .catch(error => {
                this.isGuestProcessing = false
                this.$emit('guestProcessing', this.isGuestProcessing);
                console.log(error);
            });
        },
        sendEditCredentials(userCredentials){
            this.isGuestProcessing = true
            this.$emit('guestProcessing', this.isGuestProcessing);

            axios
            .post(window.Routes.post_update_user_users_path(), {
                format: 'json',
                guest: userCredentials
            })
            .then(response => {
                this.isGuestProcessing = false
                this.$emit('guestProcessing', this.isGuestProcessing);

                if (response.data.success){
                    localStorage.setItem("guestId", response.data.data.guestId)
                    localStorage.setItem("guestName", response.data.data.guestName)
                    localStorage.setItem("pointBalance", response.data.data.ratalityPointBalance)
                    localStorage.setItem("guestPromocodes", response.data.data.guestPromocodes)
                    localStorage.setItem("guestIsAgent", response.data.data.isAgent)
                    this.switchGuestDialog('close');

                    this.$emit('snackBarTrigger', {text: `Hi ${response.data.data.guestName}. Your details have been successfully updated.`, duration:5000});
                    this.$emit('setNoCurrentUser', false);
                }else{
                    this.clearLocalStorage();
                    this.$emit('snackBarTrigger', {text: response.data.message, duration:5000});
                    // this.$emit('setNoCurrentUser', true);
                }
            })
            .catch(error => {
                this.clearLocalStorage();
                this.$emit('snackBarTrigger', {text: error.message, duration:8000});
                this.isGuestProcessing = false;
                this.$emit('guestProcessing', this.isGuestProcessing);
            });
        },
        sendForgotPasswordEmail(email){
            this.isGuestProcessing = true
            this.$emit('guestProcessing', this.isGuestProcessing);

            axios
            .post(window.Routes.post_forgotten_password_mail_users_path(), {
                format: 'json',
                email: email
            })
            .then(response => {
                this.isGuestProcessing = false
                this.$emit('guestProcessing', this.isGuestProcessing);

                this.switchGuestDialog('close');
                this.clearLocalStorage();
                this.$emit('snackBarTrigger', {text: response.data.message, duration:5000});
            })
            .catch(error => {
                this.clearLocalStorage();
                this.$emit('snackBarTrigger', {text: error.message, duration:8000});
                this.isGuestProcessing = false;
                this.$emit('guestProcessing', this.isGuestProcessing);
            });
        },
        clearLocalStorage(){
                localStorage.clear("guestId");
                localStorage.clear("guestName");
                localStorage.clear("pointBalance");
                localStorage.clear("guestPromocodes");
                localStorage.clear("guestIsAgent");
                this.$emit('setNoCurrentUser', true);        
        }
    },
    

}
</script>
