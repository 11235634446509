
<template>
  <div v-if="roomTypes"> 
    <v-app-bar 
      dark 
      fixed 
      app>
        <v-btn icon @click="gotoQueryForm">
            <v-icon>mdi-keyboard-backspace</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title >Select a room</v-toolbar-title>  
        <v-menu
          left
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="menuClick()"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-card color="primarycolor" dark depressed elevation="0" v-if="loggedInGuestId && loggedInGuestId.length" class="profilepanel rounded-0">
            <v-card-title class="text-subtitle-2 white--text">{{loggedInGuestName}}</v-card-title>
            <v-card-subtitle class="text-caption">Rands Earned:<br>ZAR {{loggedInGuestPoints}}.00</v-card-subtitle>
          </v-card>
          <v-list dense class="profilelist">
            <v-list-item>
              <v-list-item-icon>
                <v-icon >mdi-help-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="assistanceDialog = true">Need Assistance</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item style="cursor:default;">
              <v-list-item-icon>
                <v-icon >mdi-application</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="$emit('showPrivacyPolicy', true);">Privacy Policy</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item style="cursor:default;">
              <v-list-item-icon>
                <v-icon >mdi-application</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title @click="$emit('showTermsAndConditions', true);">Terms and Conditions</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Payments are secured by
                  <a href="https://www.peachpayments.com/#/products/payments" target="_blank">
                     <v-img src="peach_payment_logo_square.png" width="140" style="margin: auto;"></v-img>
                  </a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
    </v-app-bar>

    <v-card color="transparent" >
      <v-card-text class="hide-scrollbar pa-0">
        <v-card flat class="booking-details" >
          <v-card-title v-html="searchParams.hotelName"></v-card-title>
          <v-card-subtitle>
            <v-icon small color="primarycolor">mdi-calendar-range</v-icon> {{displayCheckIn(searchParams.from)}} - {{displayCheckOut(searchParams.to)}}<br>
            <div v-if="searchParams.hotelId == 8101 || searchParams.hotelId == 8102 "><v-icon small color="primarycolor">mdi-alert-circle-outline</v-icon> All rates exclude conservation levy <small>(<a href="javascript:void(0)" @click="conservationLevyDialog = true">What's this?</a>)</small>.</div>
          </v-card-subtitle>
          <v-card-text>
            <div class="booking-subdetails d-flex justify-space-between">
              <div>
                <span><v-icon color="primarycolor" small>mdi-human-male</v-icon> {{adultCountText()}}</span><span v-if="getChildrenCount() > 0" class="ml-2"><v-icon color="primarycolor" small>mdi-human-male-boy</v-icon> {{childrenCountText()}}</span>
              </div>
              <div>
                <!--TODO if only 1 night = text must read night, not nights-->
                <span><v-icon color="primarycolor" small>mdi-bed</v-icon> {{numberOfNightsShortText()}}</span>
              </div>
            </div>
          </v-card-text>
        </v-card>

        <v-container fluid>
          <v-row>
            <v-col cols="12" v-for="roomType in roomTypes" :key="roomType.roomTypeId" class=" info-box">

              <v-card class="rounded-md elevated">
                <v-carousel height="300" hide-delimiters>
                  <v-chip color="pinkgradient" class="white--text text-uppercase pinkgradient overlay-chip">
                    {{getRoomsLeftLabel(roomType)}}
                  </v-chip>
                  <v-carousel-item
                    v-for="(image, i) in roomType.fileEntries"
                    :key="i"
                    :src="image.url"
                    :eager="true"
                  ></v-carousel-item>
                  <v-img src="https://via.placeholder.com/600x250?text=No+Image" height="300px" v-if="!roomType.fileEntries || roomType.fileEntries.length == 0 "></v-img>
                  <!--<v-card class="" style="position:relative" color="rgb(39,39,39,0.4" height="41px">-->
                  <!--  <v-card-title class="white--text text-uppercase mt-n11 px-6 pt-2"  >-->
                      
                  <!--  </v-card-title>-->
                  <!--</v-card>-->
                  <div class="room-title">
                    {{roomType.name}}
                  </div>
                </v-carousel>
                <v-list >
                  <v-list-item>
                    <v-list-item-content style="width:70%;">
                      <v-list-item-title>
                        People per room
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="text-center" style="display:block;">
                      <v-list-item-title>
                        Rooms
                      </v-list-item-title>
                    </v-list-item-action>
                  </v-list-item>
                  <template v-for="(rate,index) in roomType.rates">
                    <v-list-item  :key="rate.id + '_' + index" v-show="roomAvailable(rate,roomType)">
                      <v-list-item-content style="width:70%;">
                        <v-list-item-title>
                          <v-list-item-subtitle v-html="occupancyBreakdown(rate.occupancyIcons)"></v-list-item-subtitle>
                        </v-list-item-title>
                        <v-list-item-subtitle><strong>{{rate.price.currency}} {{rate.price.value}}</strong> <small>{{numberOfNightsText()}}</small></v-list-item-subtitle>
                        <v-list-item-subtitle><strike v-if="rate.price.value != rate.rackRate">{{rate.price.currency}} {{rate.rackRate}}</strike><br v-if="rate.price.value != rate.rackRate"><small>{{rate.rateDescription}}</small></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action class="d-flex align-center justify-center flex-row">
                        <v-btn   color="secondarycolor" small dark rounded  @click="increaseRoomCount(rate)" :disabled="!roomAvailable(rate,roomType)">Add</v-btn> 
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <v-list-item>
                    <v-list-item-subtitle><v-icon color="primarycolor" small>mdi-qrcode</v-icon> <small>{{getPromoCode(roomType)}}</small></v-list-item-subtitle>
                  </v-list-item>
                  
                </v-list>
                
                <v-list class="py-0 rounded-b-md" style="overflow:hidden">
                  <v-list-group color="primarycolor" class="more-info">
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title class="text-center">More details</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-container fluid>
                      <v-row>
                        <!-- <v-col cols="12" class="pt-0">
                          <v-list-item v-if="roomType.shortDesc">
                            <v-list-item-content v-text="roomType.shortDesc"></v-list-item-content>
                          </v-list-item>
                        </v-col> -->
                        <v-col cols="12" class="py-0">
                          <v-list-item v-if="roomType.longDesc">
                            <v-list-item-content v-text="roomType.longDesc"></v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col class="ma-0 pa-0 room-details" cols="6" >
                          <v-list-item>
                            <v-list-item-avatar tile color="transparent" class="border-1" style="border:solid 1px #393939!important;border-radius:2px!important;">
                              <v-icon class="text-h4" color="black">mdi-human-male</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content >
                              <v-list-item-title >Occupancy</v-list-item-title>
                              <v-list-item-subtitle >min: {{roomType.minOccupancy}}, max: {{roomType.maxOccupancy}}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col cols="6" class="ma-0 pa-0 room-details" v-for="feature in roomType.roomTypeFeatures" :key="feature.id">
                          <v-list-item>
                            <v-list-item-avatar tile color="transparent">
                              <v-img :src="feature.fileUrl"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content >
                              <v-list-item-title v-text="feature.name"></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-list-group>
                </v-list>
              </v-card>

            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    
    <v-fab-transition>
      <v-btn
        @click="sheetBasket = !sheetBasket"
        dark
        color="primarycolor"
        fixed
        bottom
        fab
        elevation="10"
        class="arrow-button"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-dialog
      v-model="isSearching"
      
      persistent
      width="300"
      >
      <v-card
          color="primarycolor"
          dark
      >
          <v-card-text>
           <div class="py-1">Processing selection...</div>
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
          </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="conservationLevyDialog"
        scrollable
        max-width="599px"
    >
      <v-card rounded class="pa-3">
          <v-card-text v-html="conservationLevy"></v-card-text>
          <v-card-actions  class="pa-3">
              <v-btn
                  color="green darken-1"
                  text
                  @click="conservationLevyDialog = false"
              >
                  Close
              </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog> 

    <assistance-dialog v-bind:assistanceDialog="assistanceDialog"  @reverseFlag="assistanceDialog = $event" />
    <v-bottom-sheet 
      v-model="sheetBasket"
      inset
    >
      <v-sheet style="border-radius: 5px 5px 0 0;">
        <div class="pb-3">
          <div class="accommodate-container"><div class="accommodate" style="overflow: none; text-overflow: none; white-space: normal; font-weight-bold" v-html="peopleLeftToAccommodate(roomTypes)"></div></div>
            <v-list dense>

              <v-list-item class="justify-space-between">
                  <h4 class="flex-grow-1">Summary</h4>
                  <!-- <v-btn
                    depressed
                    dense
                    icon
                    @click="sheetBasket = !sheetBasket"
                    color="primarycolor"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn> -->
                
              </v-list-item>
              
               <template v-for="roomType in roomTypes" >
                 <template v-for="(rate,index) in roomType.rates" >
                  <v-list-item   v-if="rate.roomCount > 0" :key="index" >
                    <v-list-item-icon style="margin-right:10px!important;">
                      <v-btn  class="mx-2"
                        depressed
                    dense
                    icon
                        x-small
                        color="red" @click="decreaseRoomCount(rate)" v-show="rate.roomCount > 0">
                          <v-icon dark >mdi-close</v-icon>
                      </v-btn> 
                  </v-list-item-icon>
                  
                    <v-list-item-content class="text-left">         
                      <v-list-item-title v-html="roomOccupancyBreakdown(rate, roomType)"></v-list-item-title>
                    </v-list-item-content>
                    
                    <v-list-item-action>
                      <v-list-item-title><span class="summary-room-price">{{ratePriceFormatForRoom(rate)}}</span></v-list-item-title>
                    </v-list-item-action>
                    
                  </v-list-item>  
                </template>
              </template>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content class="text-left"> 
                  <v-list-item-title><h3>Total</h3></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-title ><h3>{{roomTotalPriceFormat(roomTypes)}}</h3></v-list-item-title> 
                </v-list-item-action>
              </v-list-item>
             
              <v-list-item>
                <v-list-item-content   v-if="timeToProceed()">
                  <div class="text-center">
                    <v-btn
                      
                      dark
                      rounded
                      
                      color="secondarycolor"
                      @click="gotoArticles"
                      :loading="loading == isSearching" 
                    >
                      Proceed</v-btn>
                  </div>    
                </v-list-item-content>
                <v-list-item-content   v-if="!timeToProceed()  && !noRoomsLeft ">
                  <div class="text-center">
                    <v-btn
                      
                      dark
                      rounded
                      
                      color="secondarycolor"
                      @click="sheetBasket = !sheetBasket"  
                      class="mx-auto"
                    >
                      Add a room to continue</v-btn>
                  </div>    
                </v-list-item-content>
                <v-list-item-content   v-if="!timeToProceed()  && noRoomsLeft ">
                  <v-list-item-title><h3>Opps! There are no more rooms left to select! </h3></v-list-item-title>
                </v-list-item-content> 
              </v-list-item >
              
            </v-list>
            <div class="assistance">Please contact our team if you need any assistance</div>
          
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>

import moment from 'moment';
import axios from "axios";

export default {
  props: {
    resultSet: Object,
    isSearching: Boolean,
    conservationLevy: String,
  },
  data: () => ({
    roomTypes: Array,
    searchParams: Object,
    loading: true,
    rateSelection: [],
    totalAdultsToAccommodate: Number,
    totalChildrenToAccommodate: Number,
    adultsAccommodated: Number,
    children1Accommodated: Number,
    children2Accommodated: Number,
    children3Accommodated: Number,
    children4Accommodated: Number,
    sheetBasket: false,
    numberOfNights: Number,
    totalRooms: 0,
    noRoomsLeft: false,
    assistanceDialog:false,
    conservationLevyDialog:false,

    loggedInGuestId:String,
    loggedInGuestName:String,
    loggedInGuestPoints:String,
    loggedInGuestPromocodes:String,
  }),
  mounted() {
    window.onpopstate = event => {
      if (this.sheetBasket){
        // close basketSheet onbrowser back button
        history.pushState(null, document.title, location.href);
        this.sheetBasket = false;
      }else{
        this.$emit('gotoQueryForm')
      }
       
    };
  },
  created() {
    history.pushState(null, document.title, location.href);
    this.initialize();
  },
  watch:{
    totalRooms: function(newVal, oldVal){
      if (newVal == 0 && oldVal > 0 ){
        this.noRoomsLeft = true;
      }else if(newVal > 0 && oldVal == 0){
        this.noRoomsLeft = false;
      }
    },
    sheetBasket: function(newVal, oldVal){
      if (newVal && !oldVal){
        history.pushState(null, document.title, location.href);
      }
    }
    
  },
  methods: {
    initialize() {
      //init method code goes here
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
      });
      console.log("select room form: ", this.resultSet);
      this.roomTypes =  this.resultSet.roomTypeResult;
      this.numberOfNights = this.roomTypes[0].rates[0].days.length
      this.searchParams = this.resultSet.searchParams;
      this.totalAdultsToAccommodate = this.searchParams.adultCount;
      this.totalChildren1ToAccommodate = this.searchParams.children1Count;
      this.totalChildren2ToAccommodate = this.searchParams.children2Count;
      this.totalChildren3ToAccommodate = this.searchParams.children3Count;
      this.totalChildren4ToAccommodate = this.searchParams.children4Count;
      this.adultsAccommodated = 0;
      this.children1Accommodated = 0;
      this.children2Accommodated = 0;
      this.children3Accommodated = 0;
      this.children4Accommodated = 0;

      

       //reset roomCount
      this.roomTypes.forEach(roomType => {
        roomType.rates.forEach(rate => {
          if (rate.roomCount > 0){
            this.increaseAcommodated(rate, rate.roomCount);
          }
        })
      });
      
      if (this.adultsAccommodated > 0){
        this.sheetBasket = true;
      }else{
        this.sheetBasket = false;
      }

      this.calcTotalRoomsLeft();

      console.log(this.roomTypes)
    },
    menuClick(){
          this.loggedInGuestId = (localStorage.getItem('guestId') && localStorage.getItem('guestId').length > 0) ? localStorage.getItem('guestId') : null;
          this.loggedInGuestName = (localStorage.getItem('guestName') && localStorage.getItem('guestName').length > 0) ? localStorage.getItem('guestName') : null;
          this.loggedInGuestPoints = (localStorage.getItem('pointBalance') && localStorage.getItem('pointBalance').length > 0) ? localStorage.getItem('pointBalance') : null;
          this.loggedInGuestPromocodes = (localStorage.getItem('guestPromocodes') && localStorage.getItem('guestPromocodes').length > 0) ? localStorage.getItem('guestPromocodes') : null;
    },
    getRoomsLeft(roomType){
      let roomsLeft = roomType.rates[0].freeRoomsCount;
      roomType.rates.forEach(rate =>{
         roomsLeft -= rate.roomCount; 
      })
      return roomsLeft;
    },
    getRoomsLeftLabel(roomType){
      let roomsLeft = this.getRoomsLeft(roomType);
      if (roomsLeft > 0){
        return `Only ${roomsLeft} left`
      } else {
        return 'None left'
      }
    },
    adultCountText(){
      let adultCount = this.searchParams.adultCount;
      if (adultCount > 1){
        return `${adultCount} Adults`;
      }else{
        return `${adultCount} Adult`;
      }
    },
    childrenCountText(){
      let childCount = this.getChildrenCount();
      if (childCount > 1){
        return `${childCount} Children`;
      }else{
        return `${childCount} Child`;
      }
    },
    getChildrenCount(){
      return this.totalChildren1ToAccommodate + this.totalChildren2ToAccommodate + this.totalChildren3ToAccommodate + this.totalChildren4ToAccommodate
    },
    roomAvailable(rate, roomType){
      let occupancy = rate.occupancy;
      if (this.rommsLeftBaseCondition(occupancy,roomType) ){
        return true;
      }else{
        return false;
      }
    },
    rommsLeftBaseCondition(occupancy,roomType){
      let totalChildrenLeft = (this.totalChildren1ToAccommodate - this.children1Accommodated) + 
                              (this.totalChildren2ToAccommodate - this.children2Accommodated) +
                              (this.totalChildren3ToAccommodate - this.children3Accommodated) +
                              (this.totalChildren4ToAccommodate - this.children4Accommodated);
      let totalChildren = this.totalChildren1ToAccommodate + this.totalChildren2ToAccommodate + this.totalChildren3ToAccommodate + this.totalChildren4ToAccommodate
      let totalChildrenForThisRoom = occupancy[1] + occupancy[2] + occupancy[3] + occupancy[4]

      let totalAdultsLeft =   (this.totalAdultsToAccommodate - this.adultsAccommodated);    
      let totalAdults = this.totalAdultsToAccommodate;
      let totalAdultForThisRoom = occupancy[0];                     

      return  (occupancy[0] <= (this.totalAdultsToAccommodate - this.adultsAccommodated)) && 
              (occupancy[1] <= (this.totalChildren1ToAccommodate - this.children1Accommodated)) && 
              (occupancy[2] <= (this.totalChildren2ToAccommodate - this.children2Accommodated)) && 
              (occupancy[3] <= (this.totalChildren3ToAccommodate - this.children3Accommodated)) && 
              (occupancy[4] <= (this.totalChildren4ToAccommodate - this.children4Accommodated)) &&
              (this.getRoomsLeft(roomType) > 0 ) &&
              ((totalAdultForThisRoom == 1 && totalChildrenForThisRoom == 0 && totalAdultsLeft >= 1 && totalChildrenLeft == 0 ) || 
               (totalAdultForThisRoom == 1 && totalChildrenForThisRoom == 0 && totalAdultsLeft > 1 && totalChildrenLeft > 0) ||
               (totalAdultForThisRoom == 1 && totalChildrenForThisRoom > 0 && totalAdultsLeft > 1 && totalChildrenLeft > 0 ) ||
               (totalAdultForThisRoom == 1 && totalChildrenForThisRoom > 0 && totalAdultsLeft == 1 && totalChildrenLeft == totalChildrenForThisRoom ) ||

               (totalAdultForThisRoom == 2 && totalChildrenForThisRoom == 0 && totalAdultsLeft >= 2 && totalChildrenLeft == 0 ) ||
               (totalAdultForThisRoom == 2 && totalChildrenForThisRoom == 0 && totalAdultsLeft > 2 && totalChildrenLeft > 0) ||
               (totalAdultForThisRoom == 2 && totalChildrenForThisRoom > 0 && totalAdultsLeft > 2 && totalChildrenLeft > 0 ) ||
               (totalAdultForThisRoom == 2 && totalChildrenForThisRoom > 0 && totalAdultsLeft == 2 && totalChildrenLeft == totalChildrenForThisRoom ) ||

               (totalAdultForThisRoom == 3 && totalChildrenForThisRoom == 0 && totalAdultsLeft >= 3 && totalChildrenLeft == 0 ) ||
               (totalAdultForThisRoom == 3 && totalChildrenForThisRoom == 0 && totalAdultsLeft > 3 && totalChildrenLeft > 0) ||
               (totalAdultForThisRoom == 3 && totalChildrenForThisRoom > 0 && totalAdultsLeft > 3 && totalChildrenLeft > 0 ) ||
               (totalAdultForThisRoom == 3 && totalChildrenForThisRoom > 0 && totalAdultsLeft == 3 && totalChildrenLeft == totalChildrenForThisRoom ) ||

               (totalAdultForThisRoom == 4 && totalChildrenForThisRoom == 0 && totalAdultsLeft >= 4 && totalChildrenLeft == 0 ) ||
               (totalAdultForThisRoom == 4 && totalChildrenForThisRoom == 0 && totalAdultsLeft > 4 && totalChildrenLeft > 0) ||
               (totalAdultForThisRoom == 4 && totalChildrenForThisRoom > 0 && totalAdultsLeft > 4 && totalChildrenLeft > 0 ) ||
               (totalAdultForThisRoom == 4 && totalChildrenForThisRoom > 0 && totalAdultsLeft == 4 && totalChildrenLeft == totalChildrenForThisRoom ) ||

               (totalAdultForThisRoom > 4 )    )

    },
    calcTotalRoomsLeft(){
      //count total rooms 
      let tempTotalRooms = 0;
      this.roomTypes.forEach(roomType => {
        tempTotalRooms += roomType.rates[0].freeRoomsCount;
      });
       this.totalRooms = tempTotalRooms
    },

    increaseRoomCount(rate){
      this.increaseAcommodated(rate);
      rate.roomCount += 1;
      this.totalRooms -= 1;

      this.sheetBasket = true ;
    },
    decreaseRoomCount(rate){
      if ( rate.roomCount <= 0){
        return;
      }
      this.decreaseAcommodated(rate);
      rate.roomCount -= 1;
      this.totalRooms += 1;
    },
    peopleLeftToAccommodate(){
      let totalChildrenAccommodated = (this.children1Accommodated + 
                                      this.children2Accommodated + 
                                      this.children3Accommodated + 
                                      this.children4Accommodated);
      let totalChildrenToAccommodate = ( this.totalChildren1ToAccommodate + 
                                        this.totalChildren2ToAccommodate + 
                                        this.totalChildren3ToAccommodate +
                                        this.totalChildren4ToAccommodate)                                       
      let childrenToFit = totalChildrenToAccommodate- totalChildrenAccommodated;
      let adultsToFit = this.totalAdultsToAccommodate - this.adultsAccommodated; 
      let message = '';
      let adultText = '';
      let childText = '';
     
      if (adultsToFit > 1){ adultText = 'adults'}else{adultText = 'adult'};
      if (childrenToFit > 1){ childText = 'children'}else{childText = 'child'};

      if (childrenToFit == totalChildrenToAccommodate && adultsToFit == this.totalAdultsToAccommodate){
        if ((adultsToFit + childrenToFit) > 1){
          message = `You have ${adultsToFit + childrenToFit} people to accommodate.`
        }else{
          message = `You have ${adultsToFit + childrenToFit} person to accommodate.`        
        }
        
      } else if (adultsToFit > 0){
        if (childrenToFit > 0){
          message = `You still need to accommodate <strong>${adultsToFit} ${adultText}</strong> and <strong>${childrenToFit} ${childText}</strong>.`
        }else{
          message = `You still need to accommodate <strong>${adultsToFit} ${adultText}</strong>.`
        }
      } else if (childrenToFit > 0){
        message = `You still need to accommodate <strong>${childrenToFit} ${childText}</strong>.`
      } else {
        message = `Hooray! You've accommodated eveyone!`;
      }
    return message
    },
    occupancyBreakdown(occupancyArray=[], iconsOnly=false, textOnly=false){
      let descriptionArr = [];
      let iconArr = [];
      let adult = 0;
      let teen = 0;
      let child = 0;
      let baby = 0;
      occupancyArray.forEach(element => {
        if (element == 'adult'){
          adult += 1
        }
        if (element == 'teen'){
          teen += 1
        }
        if (element == 'child'){
          child += 1
        }
        if (element == 'baby'){
          baby += 1
        }
      });

      if (adult == 1 ){
         descriptionArr.push('1 Adult')
         iconArr.push('<img src="./icons/adult.png" height="24px" />')
      }
      if (adult > 1){
         descriptionArr.push(`${adult} Adults`);
         [...Array(adult).keys()].forEach(element => {
           iconArr.push('<img src="./icons/adult.png" height="24px" />');
         });
      }
      if (baby == 1){
         descriptionArr.push('1 Baby')
         iconArr.push('<img src="./icons/child.png" height="24px" />');
      }else if(baby > 1){
          descriptionArr.push(`${baby} Babies`);
          [...Array(baby).keys()].forEach(element => {
           iconArr.push('<img src="./icons/child.png" height="24px" />');
         });
      }
      if (child == 1){
         descriptionArr.push('1 Child')
         iconArr.push('<img src="./icons/child.png" height="24px" />');
      }else if(child > 1){
          descriptionArr.push(`${child} Children`);
          [...Array(child).keys()].forEach(element => {
           iconArr.push('<img src="./icons/child.png" height="24px" />');
         });
      }
      if (teen == 1){
         descriptionArr.push('1 Teen')
         iconArr.push('<img src="./icons/child.png" height="24px" />');
      }else if(teen > 1){
          descriptionArr.push(`${teen} Teens`);
          [...Array(teen).keys()].forEach(element => {
           iconArr.push('<img src="./icons/child.png" height="24px" />');
         });
      }
        
      if (iconsOnly){
        return iconArr.join('') ;
      } else if (textOnly){
        return '<span style="font-size:11px; color: #adacac">' + descriptionArr.join(', ') + '</span>';
      }else {
        return iconArr.join('') + ' <span style="font-size:11px; color: #adacac">' + descriptionArr.join(', ') + '</span>';
      } 
      

    },
    timeToProceed(){
      let totalChildrenAccommodated = (this.children1Accommodated + 
                                      this.children2Accommodated + 
                                      this.children3Accommodated + 
                                      this.children4Accommodated);
      let totalChildrenToAccommodate = ( this.totalChildren1ToAccommodate + 
                                        this.totalChildren2ToAccommodate + 
                                        this.totalChildren3ToAccommodate +
                                        this.totalChildren4ToAccommodate)                                       
      let childrenToFit = totalChildrenToAccommodate- totalChildrenAccommodated;
      let adultsToFit = this.totalAdultsToAccommodate - this.adultsAccommodated; 

      if (childrenToFit == 0 && adultsToFit == 0){
        return true;
      }else{
        return false;
      }
    },
    increaseAcommodated(rate, multiplier = 1){
      let adultCount = rate.occupancy[0] * multiplier;
      let children1Count = rate.occupancy[1] * multiplier;; 
      let children2Count = rate.occupancy[2] * multiplier;; 
      let children3Count = rate.occupancy[3] * multiplier;;
      let children4Count = rate.occupancy[4] * multiplier;; 
      this.adultsAccommodated += adultCount;
      this.children1Accommodated += children1Count;
      this.children2Accommodated += children2Count;
      this.children3Accommodated += children3Count;
      this.children4Accommodated += children4Count;
    },
    decreaseAcommodated(rate){
      let adultCount = rate.occupancy[0];
      let children1Count = rate.occupancy[1]; 
      let children2Count = rate.occupancy[2]; 
      let children3Count = rate.occupancy[3];
      let children4Count = rate.occupancy[4]; 
      this.adultsAccommodated -= adultCount;
      this.children1Accommodated -= children1Count;
      this.children2Accommodated -= children2Count;
      this.children3Accommodated -= children3Count;
      this.children4Accommodated -= children4Count;
    },
    numberOfNightsText(){
      if (this.numberOfNights > 1){
        return `for ${this.numberOfNights} nights`
      }else{
        return `for ${this.numberOfNights} night`
      }
    },
    numberOfNightsShortText(){
      if (this.numberOfNights > 1){
        return `${this.numberOfNights} Nights`
      }else{
        return `${this.numberOfNights} Night`
      }
    },
    roomOccupancyBreakdown(rate, roomType){
      return `<span style="font-size:14px;margin-right:5px;"> ${rate.roomCount}x</span> <span style="font-size:14px;">${roomType.name}</span><br>${this.occupancyBreakdown(rate.occupancyIcons,false,true) }`
    },
    roomCalcCount(roomType){
      let roomName = roomType.name;
      return `${this.calcCount(roomType)}x ${roomName}`
    }, 
    calcCount(roomType){
      let roomCount = 0;
      roomType.rates.forEach(rate => {
          roomCount += rate.roomCount;
      });
      return roomCount;
    },
    roomCalcPrice(rate){
      return rate.roomCount * rate.price.numeric / 100; 
    },
    roomPriceFormat(roomType){
      let price = 0;
      let currency = roomType.rates[0].price.currency;

      roomType.rates.forEach($rate => {
          price += this.roomCalcPrice($rate);
      });
      return `${currency} ${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
    },
    ratePriceFormatForRoom(rate){
      let price = rate.roomCount * rate.price.numeric / 100;
      let currency = rate.price.currency;
      return `${currency} ${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
    },
    roomTotalPriceFormat(roomTypes){
      let currency = roomTypes[0].rates[0].price.currency;
      let price = 0;
      roomTypes.forEach($roomType => {
        $roomType.rates.forEach($rate => {
            price += this.roomCalcPrice($rate);
        });
      });
      return `${currency} ${price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
    },
    peopleCalcCount(roomType){
       let peopleCount = 0;
      roomType.rates.forEach(rate => {
          peopleCount += rate.occupancyIcons.length * rate.roomCount;
      });
      if (peopleCount > 1){
        return `${peopleCount} people accommodated`;
      }else{
        return `${peopleCount} person accommodated`;
      }
      
    },
    getPromoCode(roomType){
      console.dir(roomType)
      let descript = '';
      if (roomType.rates[0].name == 'BESTAV'){
        descript = 'Best available rate has been applied';
      }else if(roomType.rates[0].name == 'BOOKDIRECT10'){
        descript = "Extra discount applied - guest is registered on Lion Roars Bookings"
      }else if(roomType.rates[0].name == 'PAYSTAY'){
        descript = "Extra discount applied - booking is 3 nights or more"
      }else if(roomType.rates[0].name == 'LM3'){
        descript = "Extra discount applied - booking is 3 days or less prior to stay";
      }else if(roomType.rates[0].name == 'LM7'){
        descript = "Extra discount applied - booking is 4 to 7 days prior to stay";
      }else if(roomType.rates[0].name == 'LM14'){
        descript = "Extra discount applied - booking is 8 to 14 days prior to stay";
      }else if(roomType.rates[0].name == 'LM30'){
        descript = "Extra discount applied - booking is 15 to 30 days prior to stay";
      }else if(roomType.rates[0].promocode.length > 0){
        descript = `Promotion applied - ${roomType.rates[0].promocode}`;
      }else{
        descript = `${roomType.rates[0].rateDescription} rack rate has been applied`;
      }
      return descript
    },
    gotoQueryForm(){
        this.$emit('gotoQueryForm')
    },
    gotoArticles(){
      this.rateSelection = [];
      this.roomTypes.forEach($roomType => {
        $roomType.rates.forEach($rate => {
          if ($rate.roomCount > 0){
            this.rateSelection.push($rate);
          }
        });
      });
     this.$emit('getArticles', {rateSelection: this.rateSelection, searchParams: this.searchParams})
    },
    displayCheckIn($date){
      return moment($date).format('DD MMM YYYY')
    },
    displayCheckOut($date){
      return moment($date).format('DD MMM YYYY')
    },
  }
};
</script>
