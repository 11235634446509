<template>
    <div class="text-center" >
            <v-dialog
                v-model="dialogLoading"
                hide-overlay
                persistent
                width="300"
                >
                <v-card
                    class="pt-2"
                    color="primarycolor"
                    dark
                >
                    <v-card-text>
                    <div class="py-5">{{loadingText}}</div>
                    <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                    ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-sheet
                :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
                class=""
                >
                <v-skeleton-loader
                    class="mx-auto"
                    type=" table-heading, divider"
                ></v-skeleton-loader>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12"  v-for="index in 3" :key="index">
                            <v-skeleton-loader
                            class="mx-auto"
                            type=" card-heading, list-item-two-line, image, article, divider, image, article"
                            ></v-skeleton-loader>
                        </v-col>
                    </v-row>
                </v-container>
            </v-sheet>
           
        </div>
        
</template>

<script>
export default {
    inject: {
      theme: {
        default: { isDark: false },
      },
    },
    props:{
        loadingText: String
    },
   data: () => ({
        dialogLoading: true
    }),
    created() {
    this.initialize();
    },
    methods: {
        initialize() {
            //init method code goes here
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'auto'
            });
        }
    }
}
</script>